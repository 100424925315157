<template>
    <el-dialog :title="title" :visible.sync="show" :width="width" :close-on-click-modal="closeOnClickModal" :append-to-body="appendToBody">
        <div style="display: flex; flex-direction: column;">
            <slot name="top"></slot>
            <div style="display: flex; flex-direction: row;">
                <slot name="left"></slot>
                <div style="flex: 1">
                    <detail-form  :data="data" :column="column" :labelStyle="labelStyle">
                        <template v-for="(item, index) in data" v-slot:[item.slots]>
                            <slot :name="item.slots"></slot>
                        </template>
                    </detail-form>
                </div>
                <slot name="right"></slot>
            </div>
            <slot name="bottom"></slot>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="handleClose">关 闭</el-button>
        </div>
    </el-dialog>
</template>

<script>
import DetailFrom from './DetailForm.vue'
export default {
    name: 'detail-form-dialog',
    components: {
        'detail-form': DetailFrom
    },
    props: {
        title: {
            type: String
        },
        visible: {
            type: Boolean
        },
        width: {
            type: String
        },
        closeOnClickModal: {
            type: Boolean,
            default: true
        },
        appendToBody: {
            type: Boolean,
            default: true
        },
        column: {
            type: Number,
            default: 2
        },
        labelStyle: {
            type: String,
            default: 'min-width: 80px;'
        },
        data: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            ds: this.data,
            show: this.visible
        }
    },
    methods: {
        handleClose() {
            this.show = false
            this.$emit('update:visible', this.show)
        },
        open(data) {
            if (data != undefined) {
                this.ds = data
            }
            this.show = true
        }
    },
    watch: {
        visible() {
            this.show = this.visible
        },
        show() {
            this.$emit('update:visible', this.show)
        }
    }
}
</script>
<template>
	<div class="columns-btn">

		<el-popover placement="bottom-end" width="350" v-model="displayVisible">
			<div>
				<div class="font-size-20 mb-10">
					显示列
				</div>
				<div>
					<el-input placeholder="输入筛选条件" v-model="filterKeywords" suffix-icon="el-icon-search"></el-input>
					<div class="column-list">
						<div>
							<el-checkbox :indeterminate="checkboxLength!==0&&checkboxLength !==list.length "
								:value="checkboxLength===list.length" @change="allCheckboxChange">全选
								{{checkboxLength}}/{{list.length}}</el-checkbox>
							<!-- <i class="iconfont " @click="allCheckboxChange"
								:class="checkboxLength===list.length?'text-primary icon-checkbox-checked-fill':(checkboxLength===0?'icon-square':'text-primary icon-zhankai3')"></i> -->

						</div>
						<draggable v-model="filterList" @end="endDrag">
							<div class="column-list-item" @mousedown="startDrag(index)"
								v-for="(item,index) in filterTypes" :key="index">
								<!-- <i class="iconfont icon"
									:class="item.isCheck?'text-primary icon-checkbox-checked-fill':'icon-square'"
									@click="checkboxChange(item)"></i> -->
								<el-checkbox :value="item.isCheck" @change="checkboxChange(item)"></el-checkbox>
								<div v-text="item.title" class="ml-10 flex-1"></div>
								<i class="iconfont  icon icon-caiddang"
									:class="item.fixed==='left'?'icon-pin-full':'icon-pin-copy'"
									@click="fixedChange(item)"></i>
								<!-- <el-checkbox 
									:value="item.fixed==='left'" @change="fixedChange(item)">全选
									{{checkboxLength}}/{{list.length}}</el-checkbox> -->
								<i class="iconfont ml-10 icon-apps icon-caiddang"></i>
							</div>
						</draggable>
					</div>
				</div>
			</div>
			<el-button class="btn-toolbar" slot="reference">
				<i class="iconfont icon-icon-yxj-remark font-size-14"></i>
				显示列
			</el-button>
		</el-popover>
	</div>

</template>

<script>
	import draggable from "vuedraggable";
	export default {
		components: {
			draggable
		},
		props: {
			list: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				startIndex: "",
				typeVisible: false,
				isAll: false,
				visible: false,
				displayVisible: false,
				filterKeywords: "",
				filterActive: [],
				columnList: [],

				// tableTitleColumn: [],
				calculationTypes: [{
						value: ">",
						lable: '大于',
					}, {
						value: "<",
						lable: '小于'
					}, {
						value: "between",
						lable: '介于'
					}, {
						value: "=",
						lable: '大于'
					},
					{
						value: 4,
						lable: '大于等于'
					}, {
						value: 5,
						lable: '小于等于'
					}
				],
				filterList: [],
				//是否固定
				fixedList: [],
			}
		},
		computed: {
			filterTypes: function() {
				return this.filterList.filter(o => o.title && o.title !== '操作' && o.title.indexOf(this
					.filterKeywords) !== -1)
			},
			checkboxLength: function() {
				let index = 0
				this.filterList.forEach(item => {
					if (item.isCheck) {
						index += 1
					}
				})
				return index
			}
		},
		created() {
			
		},
		watch: {
			list: {
				handler(val) {
					this.filterList = this.deepCopy(this.list)
               
					// this.filterList.forEach(o => {
					// 	if (!(typeof o.isCheck === 'boolean')) {
					// 		o.isCheck = true
					// 	}
					// })
				},
				//此属性是控制是否首次加载就会执行，如果不写immediate，或者写immediate：false，
				//两者相同都是首次加载不会执行
				immediate: true,
				//deep:true,深度监听，可以监听对象属性
				deep: true

			},


		},
		methods: {
			//全选
			allCheckboxChange() {
				if (this.checkboxLength === this.filterList.length) {
					this.filterList.forEach(o => o.isCheck = false)
				} else {
					this.filterList.forEach(o => o.isCheck = true)
				}
			},
			//选择
			checkboxChange(item) {
				let i = this.filterList.findIndex(o => o.field === item.field)
				this.filterList[i].isCheck = !this.filterList[i].isCheck
				this.$emit("sortConfirm", {
					data: this.filterList,
					type: 'check'
				});
			},
			fixedChange(item) {
	
				let i = this.filterList.findIndex(o => o.field === item.field)
				// this.filterList[i].fixed = this.filterList[i].fixed === 'left' ? '' : 'left'
				this.$set(this.filterList[i], 'fixed', this.filterList[i].fixed === 'left' ? '' : 'left')
				this.$emit("sortConfirm", {
					data: this.filterList,

				});
			},
			startDrag(index) {
				// 获取拖拽起始位置的索引值
				if (index > -1) {
					let field = this.filterTypes[index].field

					this.startIndex = this.filterList.findIndex(o => o.field === field);
				}
			},
			endDrag(event) {
				// 获取拖拽结束位置的索引值
				if (event.newIndex > -1) {
					let endIndex = event.newIndex;
					let field = this.filterTypes[endIndex].field
					let index = this.filterList.findIndex(o => o.field === field)
					this.$emit("sortConfirm", {
						data: this.filterList,
						startIndex: this.startIndex,
						index: index,
						startData: this.filterList[this.startIndex],
						endData: this.filterList[index]
					});
					// console.log(endIndex,index,title, "endDrag");
				}
			},
			isExist(list, field) {
				return list.findIndex(o => o.field === field)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.columns-btn {
		display: inline-block;
		// float: right;


	}

	.text-primary {
		color: #1890ff;
	}

	.d-flex {
		display: flex;
	}

	.flex {
		&-1 {
			flex: 1;

		}

		&-2 {
			flex: 2;
		}

		&-3 {
			flex: 3;
		}

	}

	.font-size-20 {
		font-size: 20px;
	}

	.text-center {
		text-align: center;
	}

	.align-items-center {
		align-items: center;
	}

	.ml-10 {
		margin-left: 10px;
	}

	.mb-10 {
		margin-bottom: 10px;
	}







	.btn-toolbar {
		// float: right;
		border: none !important;
		margin: 0 12px 0 0 !important;
		padding: 0 0.5em !important;
		height: 32px;
		line-height: 32px;

		&-icon {
			margin-right: 5px;
		}
	}

	.columns-btn .btn-toolbar:hover,
	.columns-btn .btn-toolbar:focus {

		background: transparent !important;
	}

	.columns-btn .vxe-tools--wrapper {
		.vxe-button {
			border: none !important;
			margin: 0 12px 0 0 !important;
			padding: 0 0.5em !important;
		}



	}

	.column-list {
		padding: 15px;
		max-height: 300px;
		overflow-y: auto;

		&-item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-radius: 5px;
			line-height: 1;
			padding: 10px;
			margin-top: 10px;
			border: 1px solid rgba(0, 0, 0, 0.05);
			background: #fff;

			.icon {
				position: relative;
				z-index: 9999;
			}

			// &:hover {
			// 	border: 1px solid #1890ff;
			// 	color: #1890ff;
			// }

		}
	}

	// .custom-dragging {
	// 	background-color:#1890ff;
	// }

	.sortable-ghost {
		background: #fff !important;
		// opacity: 0 !important;

	}

	.sortable-chosen {
		.icon-caiddang {
			color: #1890ff;
		}

		background: #fff !important;
		border: 1px solid #1890ff;
		color: #1890ff;
		opacity: 1 !important;
	}

	// .column-list-item 
</style>
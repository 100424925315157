import request from '@/utils/request'

// 查询机构列表
export function listDept(query) {
  return request({
    url: '/dept/list',
    method: 'get',
    params: query
  })
}

// 查询机构列表（排除节点）
export function listDeptExcludeChild(deptId) {
  return request({
    url: '/dept/exclude?id=' + deptId,
    method: 'get'
  })
}

// 查询机构详细
export function getDept(deptId) {
  return request({
    url: '/dept/get?id=' + deptId,
    method: 'get'
  })
}

// 查询机构下拉树结构
export function treeselect() {
  return request({
    url: '/dept/tree',
    method: 'get'
  })
}

// 根据角色ID查询机构树结构
export function roleDeptTreeselect(roleId) {
  return request({
    url: '/dept/tree?roleId=' + roleId,
    method: 'get'
  })
}

// 新增机构
export function addDept(data) {
  return request({
    url: '/dept/add',
    method: 'post',
    data: data
  })
}

// 修改机构
export function updateDept(data) {
  return request({
    url: '/dept/edit',
    method: 'post',
    data: data
  })
}

// 删除机构
export function delDept(deptId) {
  return request({
    url: '/dept/del',
    method: 'post',
    data: {
      ids: deptId
    }
  })
}
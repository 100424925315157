/**
 * @description 登录、获取用户信息、退出登录、清除accessToken逻辑
 */

import Vue from 'vue'
import { getUserInfo, login, logout, tokenLogin } from '@/api/user'
import { getAccessToken, setAccessToken, removeAccessToken } from '@/utils/tokenTool'
import { title, tokenName } from '@/config'
import { resetRouter } from '@/router'

const state = () => ({
    accessToken: getAccessToken(),
    username: '',
    avatar: '',
    permissions: [],
    roles:[]
})

const getters = {
    accessToken: (state) => state.accessToken,
    username: (state) => state.username,
    avatar: (state) => state.avatar,
    permissions: (state) => state.permissions,
    roles: (state) => state.roles
}

const mutations = {
    setAccessToken(state, accessToken) {
        state.accessToken = accessToken
        setAccessToken(accessToken)
    },
    setUsername(state, username) {
        state.username = username
    },
    setAvatar(state, avatar) {
        state.avatar = avatar
    },
    setPermissions(state, permissions) {
        state.permissions = permissions
    },
    setRoles(state, roles) {
        state.roles = roles
    }
}

const actions = {
    //登录
    async login({ commit }, userInfo) {
        const { data } = await login(userInfo)
        // const accessToken = data[tokenName]
        const accessToken = data.access_token
        if (accessToken) {
            commit('setAccessToken', accessToken)
            const hour = new Date().getHours()
            const thisTime =
                hour < 8
                    ? '早上好'
                    : hour <= 11
                        ? '上午好'
                        : hour <= 13
                            ? '中午好'
                            : hour < 18
                                ? '下午好'
                                : '晚上好'
            Vue.prototype.$baseNotify(`欢迎登录${title}`, `${thisTime}！`)
        } else {
            Vue.prototype.$baseMessage(
                `登录接口异常，未正确返回${tokenName}...`,
                'error'
            )
            console.log(`登录接口异常，未正确返回${tokenName}...`);
        }
    },
    //token 登录
    async tokenLogin({ commit }, token) {
        const { data } = await tokenLogin(token)
        // const accessToken = data[tokenName]
        const accessToken = data.access_token
        if (accessToken) {
            commit('setAccessToken', accessToken)
        } else {
            Vue.prototype.$baseMessage(
                `登录接口异常，未正确返回${tokenName}...`,
                'error'
            )
        }
    },
    async getUserInfo({ commit, state }) {
        const { data } = await getUserInfo(state.accessToken)
        if (!data) {
            Vue.prototype.$baseMessage('验证失败，请重新登录...', 'error')
            return false
        }
        let { permissions, roles } = data
        let { nickname, avatar } = data.user
        if (permissions && roles && roles.length > 0 && Array.isArray(permissions)) {
            commit('setPermissions', permissions)
            commit('setRoles', roles)
            commit('setUsername', nickname)
            commit('setAvatar', avatar)
            return roles
        } else {
            Vue.prototype.$baseMessage('用户信息接口异常', 'error')
            return false
        }

    },
    async logout({ dispatch }) {
        // await logout(state.accessToken)
        await dispatch('resetAccessToken')
        await resetRouter()
    },
    resetAccessToken({ commit }) {
        commit('setPermissions', [])
        commit('setAccessToken', '')
        removeAccessToken()
    },
    // 前端 登出
    FedLogOut({ commit }) {
        return new Promise(resolve => {
          commit('setAccessToken', '')
          removeAccessToken()
          resolve()
        })
      },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
/**
 * @description 所有全局配置的状态管理，如无必要请勿修改
 */

import defaultSettings from '@/config'
import {
	writeNewStyle
} from '@/utils/colorTool.js'
import variables from '@/styles/variables.scss'

const {
	tabsBar,
	logo,
	layout,
	header,
	themeBar,
	themeDrawerVisible,
	themeColor,
	themeMode
} = defaultSettings
const theme =
	JSON.parse(localStorage.getItem('qz-micro-app1-theme')) || ''
const state = () => ({
	tabsBar: theme.tabsBar || tabsBar,
	logo,
	collapse: false,
	layout: theme.layout || layout,
	header: theme.header || header,
	device: 'desktop',
	themeBar,
	themeDrawerVisible: false,
	themeColor: theme.themeColor || themeColor,
	themeMode: theme.themeMode || themeMode,
	siderBackgroundImg: theme.siderBackgroundImg || 'none',
	loginTheme: 'purpleBlue'
})
const getters = {
	collapse: (state) => state.collapse,
	device: (state) => state.device,
	header: (state) => state.header,
	layout: (state) => state.layout,
	logo: (state) => state.logo,
	tabsBar: (state) => state.tabsBar,
	themeBar: (state) => state.themeBar,
	themeDrawerVisible: (state) => state.themeDrawerVisible,
	themeColor: (state) => state.themeColor,
	themeMode: (state) => state.themeMode,
	siderBackgroundImg: (state) => state.siderBackgroundImg,
	loginTheme: (state) => state.loginTheme
}
const mutations = {
	changeLayout: (state, layout) => {
		if (layout) state.layout = layout
	},
	changeHeader: (state, header) => {
		if (header) state.header = header
	},
	changeTabsBar: (state, tabsBar) => {
		if (tabsBar) state.tabsBar = tabsBar
	},
	changeCollapse: (state) => {
		state.collapse = !state.collapse
	},
	foldSideBar: (state) => {
		state.collapse = true
	},
	openSideBar: (state) => {
		state.collapse = false
	},
	toggleDevice: (state, device) => {
		state.device = device
	},
	changeThemeDrawer: (state, data) => {
		state.themeDrawerVisible = data ? true : false
	},
	setThemeColor: (state, data) => {
		let customColor = localStorage.getItem('customColor')
		if (!data && customColor && customColor.toUpperCase() != '#FFFFFF') {
			writeNewStyle(customColor)
		} else {
			state.themeColor = data || themeColor || variables.themeColor
			writeNewStyle(state.themeColor)
		}

	},
	changeThemeMode: (state, mode) => {
		if (mode) state.themeMode = mode
	},
	changeSiderBackgroundImg: (state, img) => {
		if (img) state.siderBackgroundImg = img
	},
	//切换登录样式
	setLoginTheme: (state, data) => {
		state.loginTheme = data
	},
}
const actions = {
	changeLayout({
		commit
	}, layout) {
		commit('changeLayout', layout)
	},
	changeHeader({
		commit
	}, header) {
		commit('changeHeader', header)
	},
	changeTabsBar({
		commit
	}, tabsBar) {
		commit('changeTabsBar', tabsBar)
	},
	changeCollapse({
		commit
	}, e) {
		commit('changeCollapse')
	},
	foldSideBar({
		commit
	}) {
		commit('foldSideBar')
	},
	openSideBar({
		commit
	}) {
		commit('openSideBar')
	},
	toggleDevice({
		commit
	}, device) {
		commit('toggleDevice', device)
	},
	changeThemeDrawer({
		commit
	}, data) {
		commit('changeThemeDrawer', data)
	},
	setThemeColor({
		commit
	}, color) {
		commit('setThemeColor', color)
	},
	changeThemeMode({
		commit
	}, mode) {
		commit('changeThemeMode', mode)
	},
	changeSiderBackgroundImg({
		commit
	}, img) {
		commit('changeSiderBackgroundImg', img)
	},
	setLoginTheme({
		commit
	}, data) {
		commit('setLoginTheme', data)
	}
}
export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
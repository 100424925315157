var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layouts-wrapper",class:_vm.classObj},[('horizontal' === _vm.layout)?_c('div',{staticClass:"layout-container-horizontal",class:{
      fixed: _vm.header === 'fixed',
      'no-tabs-bar': _vm.tabsBar === 'false' || _vm.tabsBar === false,
    }},[_c('div',{class:_vm.header === 'fixed' ? 'fixed-header' : ''},[_c('top-bar'),(_vm.tabsBar === 'true' || _vm.tabsBar === true)?_c('div',{class:{ 'tag-view-show': _vm.tabsBar }},[_c('div',{staticClass:"qz-micro-main"},[_c('tabs-bar')],1)]):_vm._e()],1),_c('div',{staticClass:"qz-micro-main main-padding"},[_c('app-main')],1)]):_vm._e(),('vertical' === _vm.layout)?_c('div',{staticClass:"layout-container-vertical",class:{
      fixed: _vm.header === 'fixed',
      'no-tabs-bar': _vm.tabsBar === 'false' || _vm.tabsBar === false,
    }},[(_vm.device === 'mobile' && _vm.collapse === false)?_c('div',{staticClass:"mask",on:{"click":_vm.handleFoldSideBar}}):_vm._e(),_c('side-bar'),_c('div',{staticClass:"qz-micro-main",class:_vm.collapse ? 'is-collapse-main' : ''},[_c('div',{class:_vm.header === 'fixed' ? 'fixed-header' : ''},[_c('nav-bar'),(_vm.tabsBar === 'true' || _vm.tabsBar === true)?_c('tabs-bar'):_vm._e()],1),_c('app-main')],1)],1):_vm._e(),('column' === _vm.layout)?_c('div',{staticClass:"layout-container-column",class:{
      fixed: _vm.header === 'fixed',
      'no-tabs-bar': _vm.tabsBar === 'false' || _vm.tabsBar === false,
    }},[(_vm.device === 'mobile' && _vm.collapse === false)?_c('div',{staticClass:"mask",on:{"click":_vm.handleFoldSideBar}}):_vm._e(),_c('column-bar'),_c('div',{staticClass:"qz-micro-main",class:_vm.collapse ? 'is-collapse-main' : ''},[_c('div',{class:_vm.header === 'fixed' ? 'fixed-header' : ''},[_c('nav-bar'),(_vm.tabsBar === 'true' || _vm.tabsBar === true)?_c('tabs-bar'):_vm._e()],1),_c('app-main')],1)],1):_vm._e(),('comprehensive' === _vm.layout)?_c('div',{staticClass:"layout-container-comprehensive",class:{
      fixed: _vm.header === 'fixed',
      'no-tabs-bar': _vm.tabsBar === 'false' || _vm.tabsBar === false,
    }},[(_vm.device === 'mobile' && _vm.collapse === false)?_c('div',{staticClass:"mask",on:{"click":_vm.handleFoldSideBar}}):_vm._e(),_c('comprehensive-bar'),_c('div',{staticClass:"qz-micro-main",class:_vm.collapse ? 'is-collapse-main' : ''},[_c('div',{class:_vm.header === 'fixed' ? 'fixed-header' : ''},[_c('nav-bar'),(_vm.tabsBar === 'true' || _vm.tabsBar === true)?_c('tabs-bar'):_vm._e()],1),_c('app-main')],1)],1):_vm._e(),_c('theme-drawer'),_c('el-backtop')],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div style="display: flex; flex-direction: column;">
        <slot name="top"></slot>
        <div style="display: flex; flex-direction: row;">
            <slot name="left"></slot>
            <div style="flex: 1;font-size: 14px;">
                <el-descriptions class="margin-top detail-form" title="" :column="column" border >
                    <template v-for="(item, index) in data">
                        <slot v-if="item.slots" :name="item.slots" :item="item" :index="index"></slot>
                        <el-descriptions-item v-else :key="index" :labelStyle="labelStyle" :span="item.span">
                            <template slot="label">
                                <i v-if="item.icon" :class="item.icon"></i>
                                {{item.label}}
                            </template>
                            <template v-if="typeof item.value == 'object'">
                                <el-tag v-for="(t, i) in item.value" :key="i" type="info" size="small" :style="item.color ? ('color: ' + item.color) : ''">{{t}}</el-tag>
                            </template>
                            <template v-else>
                                <span :style="item.color ? ('color: ' + item.color) : ''">{{item.value}}</span>
                            </template>
                        </el-descriptions-item>
                    </template>
                </el-descriptions>
            </div>
            <slot name="right"></slot>
        </div>
        <slot name="bottom"></slot>
    </div>
</template>

<script>
export default {
    name: 'detail-form',
    props: {
        column: {
            type: Number,
            default: 2
        },
        labelStyle: {
            type: String,
            default: 'min-width: 80px;'
        },
        data: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            /*data: [
                {label: '用户名', value: 'test', icon: 'el-icon-user'},
                {label: '备注', value: '学校', icon: 'el-icon-tickets', slots: 'remark'},
            ]*/
        }
    }
}
</script>
<style >
	.detail-form .el-descriptions--small{
		font-size: 14px;
	}
</style>
<template> 
    <div>
        <el-upload
            class="upload-demo"
            ref="fieldEditor"
            :accept="accept"
            action="/file/upload"
            :headers="uploadHeaders"
            :data="uploadData"
            :multiple="multiple"
            :limit="limit"
            :size="size"
            :file-list="imageList"
            :disabled="disabled || readonly"
            :before-upload="handleBeforceUpload"
            :http-request="uploadDss"
         
            :on-error="handleUploadError"
            :on-exceed="handleUploadExceed"
            :on-remove="handleUploadRemove"
            :on-preview="handlePreview"
            :list-type="listType"
        >
        <!-- :on-success="handleUploadSuccess" -->
            <i
                v-if="!(disabled || readonly) && listType == 'picture-card'"
                slot="default"
                class="el-icon-plus"
            ></i>
            <el-button
                v-else-if="!(disabled || readonly)"
                size="small"
                type="primary"
                :loading="loading"
                >点击上传</el-button
            >
            <div
                v-if="!(disabled || readonly)"
                slot="tip"
                class="el-upload__tip"
            >
                {{ tip }}
            </div>
        </el-upload>
        <el-dialog
            :visible.sync="dialogVisible"
            :append-to-body="true"
            title="图片预览"
            @closed="handleDialogClose"
        >
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
    </div>
</template>

<script>
import { getAccessToken } from "@/utils/tokenTool";
import { uploadFile } from "@/api/file/file";
import {
    uploadServer,
    uploadBigApp,
    addBigOss,
    getNameByPath,
} from "@/api/dss/fastdfsServer.js";

export default {
    name: "file-upload-ex",
    props: {
        value: {
            type: String,
            default: () => {
                return null;
            },
        },
        size: {
            type: String,
            default: () => {
                return "medium";
            },
        },
        readonly: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        disabled: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        accept: {
            type: String,
            default: () => {
                return "image/png,video/mp4, video/ogg, video/flv,video/avi,video/wmv,video/rmvb,audio/mp3,image/jpeg,audio/mpeg,text/csv, text/plain, text/xml, application/xml, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, zip,application/zip,application/x-zip,application/x-zip-compressed";
            },
        },
        tip: {
            type: String,
            default: () => {
                return "只能上传图片文件(.jpg.png),办公文件(.doc.docx.xls.xlsx.ppt.pptx.pdf),文本文件(.csv.txt.xml),压缩文件(.zip),视频文件(.mp4,.ogg,.flv,.avi,.wmv,.rmvb),音频文件(.mp3,.mpeg)";
            },
        },
        multiple: {
            type: Boolean,
            default: () => {
                return true;
            },
        },
        limit: {
            type: Number,
            default: () => {
                return undefined;
            },
        },
        listType: {
            type: String,
            default: () => {
                return "picture-card";
            },
        },
        category: {
            type: String,
            default: () => {
                return "";
            },
        },
        public: {
            type: String,
            default: () => {
                return "1";
            },
        },
    },
    data() {
        return {
            uploadHeaders: {
                Authorization: getAccessToken(),
            },
            uploadData: {
                category: this.category,
                public: this.public,
            },
            imageList: [],
            dialogVisible: false,
            dialogImageUrl: "",
            loading: false,
        };
    },
    created() {
        let imageList = [];
        if (this.value) {
            imageList = JSON.parse(this.value);
        }
        this.imageList = imageList;
    },
    methods: {
        handleBeforceUpload(file) {
            if (this.accept) {
                const sps = this.accept.split(",");
                for (let i = 0; i < sps.length; i++) {
                    const s = sps[i].trim().toLowerCase();
                    if (s == file.type) {
                        return true;
                    }
                }
                return false;
            } else {
                return true;
            }
        },
        handleUploadSuccess(response, file, fileList) {
            console.log(response, file, fileList);
            const value = JSON.stringify(
                fileList.map((v) => {
                    if (v.response && v.response.data && v.response.data.url) {
                        return {
                            name: v.response.data.filename,
                            url: v.response.data.url,
                        };
                    } else {
                        return {
                            name: v.name,
                            url: v.url,
                        };
                    }
                })
            );
            console.log(value);
            this.$emit("input", value);
            this.$emit("change", value);
        },
        handleUploadError(err) {
            if (
                err &&
                (err.message.indexOf("larger than") > 0 ||
                    err.message.indexOf("413") > 0)
            ) {
                this.msgError("上传文件失败，文件大小超过限制");
            } else {
                this.msgError("上传文件失败");
            }
        },
        handleUploadExceed() {
            this.msgError("只能上传一个文件，请删除后重新上传");
        },
        handleUploadRemove(file, fileList) {
            const value = JSON.stringify(
                fileList.map((v) => {
                    if (v.response && v.response.data && v.response.data.url) {
                        return {
                            name: v.response.data.filename,
                            url: v.response.data.url,
                        };
                    } else {
                        return {
                            name: v.name,
                            url: v.url,
                        };
                    }
                })
            );
            //console.log(value)
            this.$emit("input", value);
            this.$emit("change", value);
        },
        handlePreview(file) {
            //console.log(file)
            const sps = file.name.split(".");
            if (sps.length > 1) {
                const ext = sps[sps.length - 1].toLowerCase();
                if (ext == "jpg" || ext == "jpeg" || ext == "png") {
                    this.dialogImageUrl = file.url;
                    this.dialogVisible = true;
                    return;
                }
            }
            this.downloadbyurl(file.url);
        },
        handleDialogClose() {
            this.dialogImageUrl = "";
        },
        //自定义上传
        async uploadDss(request) {
            // 默认需要登录
            let tokenReq = {
                platform: "jpgfast",
                type: "general",
                source: "system",
                public: this.public,
            };
            this.loading = true;
            uploadServer(tokenReq, request.file).then((resp) => {
                // console.log(123);
                if (resp.code == 0) {
                    // const value =
                    // //console.log(value)
                    // this.$emit("input", value);
                    // this.$emit("change", value);
                    this.imageList.push({
                        url: resp.data.url,
                        name: resp.data.filename,
                    });
                    this.loading = false;
                    this.$emit("input", JSON.stringify(this.imageList));
                    this.$emit("change", JSON.stringify(this.imageList));
                } else {
                    this.$refs.fieldEditor.uploadFiles =
                        this.$refs.fieldEditor.uploadFiles.filter((e) => {
                            if (e.name !== file.name || e.status !== "ready") {
                                return e;
                            }
                        });
                    this.loading = false;
                    this.$message.error({
                        message: "上传失败,原因：" + res.msg,
                        duration: 2000,
                    });
                }
            });
        },
    },
    watch: {
        value(val) {
            let imageList = [];
            if (val) {
                imageList = JSON.parse(val);
            }
            this.imageList = imageList;
        },
    },
};
</script>

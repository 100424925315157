import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
//require.context(检索得目录, 是否检索子文件夹, 匹配文件的正则表达式)
const modulesFiles = require.context('./modules', false, /\.js$/)
const modules = {}

modulesFiles.keys().forEach(key => {
  //  将文件路径中./ .js去掉 提取模块名 './user.js' => 'user'
  modules[key.replace(/(\.\/|\.js)/g, '')] = modulesFiles(key).default
});

export default new Vuex.Store({
  modules
})

<template>
  <el-checkbox-group v-model="selectedValue" @change="handleChange" :disabled="disabled">
    <el-checkbox
      v-for="dict in dictData.values"
      :key="dict.key"
      :label="dict.key"
    >{{dict.value}}</el-checkbox>
  </el-checkbox-group>
</template>

<script>
export default {
  name: 'dict-checkbox',
  props: {
    type: {
      type: String,
      default: () => { return null }
    },
    value: {
      type: String,
      default: () => { return null }
    },
    disabled: {
      type: Boolean,
      default: () => { return false }
    }
  },
  data() {
    return {
      dictData: {},
      selectedValue: this.value ? this.value.split(',') : []
    }
  },
  created() {
    this.getDicts(this.type).then(response => {
      if (response.code == 0) {
        this.dictData = response.data
      }
    })
  },
  methods: {
    handleChange(e) {
      const value = this.selectedValue && this.selectedValue.length > 0 ? this.selectedValue.join(',') : ''
      this.$emit('input', value)
      this.$emit('change', value)
    }
  },
  watch: {
    value(val) {
      this.selectedValue = val ? val.split(',') : []
    }
  }
}
</script>

<template>
    <div>
        <slot>
            <input-select-button
                :value="
                    label ||
                    selectedLabel ||
                    (selectedValue && defaultLabel) ||
                    selectedValue
                "
                :placeholder="placeholder"
                :size="size"
                @clear="clear"
                @click="open"
            />
        </slot>

        <el-dialog
            :close-on-click-modal="false"
            :title="placeholder"
            :visible.sync="visible"
            :width="width"
            append-to-body
        >
            <!-- <div> -->
                <!-- <el-form
                    :model="queryParams"
                    ref="queryForm"
                    :inline="true"
                    label-width="68px"
                >
                    <el-form-item
                        v-for="item in searchItems"
                        :key="item.field"
                        :label="item.title"
                        :prop="item.field"
                    >
                        <dept-select
                            v-if="item.ctype == 'dept'"
                            v-model="queryParams[item.field]"
                            :valueField="item.valueField || 'id'"
                            :placeholder="'请选择' + item.title"
                            size="small"
                        />
                        <major-select
                            v-else-if="item.ctype == 'major'"
                            v-model="queryParams[item.field]"
                            :placeholder="'请选择' + item.title"
                            :valueField="item.valueField || 'id'"
                            size="small"
                            :dept_no="queryParams['dept_no']"
                        />
                        <dict-select
                            v-else-if="item.ctype == 'dict'"
                            v-model="queryParams[item.field]"
                            :placeholder="'请选择' + item.title"
                            size="small"
                            :type="item.cvalue"
                        />
                        <gbpx-teacher-select
                            v-else-if="item.ctype == 'gbpx-teacher'"
                            v-model="queryParams[item.field]"
                            :placeholder="'请选择' + item.title"
                            size="small"
                            :type="item.cvalue"
                        />
                        <gbpx-course-category-select
                            v-else-if="item.ctype == 'gbpx-course-category'"
                            v-model="queryParams[item.field]"
                            :placeholder="'请选择' + item.title"
                            size="small"
                            :type="item.cvalue"
                        />
                        <el-input
                            v-else
                            v-model="queryParams[item.field]"
                            :placeholder="'请输入' + item.title"
                            clearable
                            size="small"
                            @keyup.enter.native="handleQuery"
                        />
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            type="cyan"
                            icon="el-icon-search"
                            size="mini"
                            @click="handleQuery"
                            >搜索</el-button
                        >
                        <el-button
                            icon="el-icon-refresh"
                            size="mini"
                            @click="resetQuery"
                            >重置</el-button
                        >
                    </el-form-item>
                </el-form>

                <vxe-grid
                    resizable
                    ref="xTable"
                    stripe
                    show-overflow
                    highlight-hover-row
                    :loading="loading"
                    :columns="tableColumn"
                    :data="dataList"
                    @checkbox-change="checkboxChangeEvent"
                    @checkbox-all="checkboxChangeEvent"
                    @radio-change="radioChangeEvent"
                >
                    <template #empty>
                        <span>
                            <p>暂无数据</p>
                        </span>
                    </template>
                </vxe-grid>

                <vxe-pager
                    background
                    size="small"
                    :loading="loading"
                    :current-page.sync="queryParams.pageNum"
                    :page-size.sync="queryParams.pageSize"
                    :total="total"
                    @page-change="handlePageChange"
                >
                </vxe-pager> -->

                <jf-table
                    :loading="loading"
                    :columns="tableColumn"
                    :data="dataList"
                    :query-params="queryParams"
                    @checkbox-change="checkboxChangeEvent"
                    @radio-change="radioChangeEvent"
                    @page-change="handlePageChange"
                    :total="total"
                    :checkbox = "multiple"
                    :radio ="!multiple"
                    @handleQuery="handleQuery"
                    :operate="false"
                >
                    <!-- @change="tableChange" -->
                    <template slot="query_qarams_item">
                        <el-form-item
                            v-for="item in searchItems"
                            :key="item.field"
                            :label="item.title"
                            :prop="item.field"
                        >
                            <dept-select
                                v-if="item.ctype == 'dept'"
                                v-model="queryParams[item.field]"
                                :valueField="item.valueField || 'id'"
                                :placeholder="'请选择' + item.title"
                                size="small"
                            />
                            <major-select
                                v-else-if="item.ctype == 'major'"
                                v-model="queryParams[item.field]"
                                :placeholder="'请选择' + item.title"
                                :valueField="item.valueField || 'id'"
                                size="small"
                                :dept_no="queryParams['dept_no']"
                            />
                            <dict-select
                                v-else-if="item.ctype == 'dict'"
                                v-model="queryParams[item.field]"
                                :placeholder="'请选择' + item.title"
                                size="small"
                                :type="item.cvalue"
                            />
                            <gbpx-teacher-select
                                v-else-if="item.ctype == 'gbpx-teacher'"
                                v-model="queryParams[item.field]"
                                :placeholder="'请选择' + item.title"
                                size="small"
                                :type="item.cvalue"
                            />
                            <gbpx-course-category-select
                                v-else-if="item.ctype == 'gbpx-course-category'"
                                v-model="queryParams[item.field]"
                                :placeholder="'请选择' + item.title"
                                size="small"
                                :type="item.cvalue"
                            />
                            <el-input
                                v-else
                                v-model="queryParams[item.field]"
                                :placeholder="'请输入' + item.title"
                                clearable
                                size="small"
                                @keyup.enter.native="handleQuery"
                            />
                        </el-form-item>
                    </template>
                </jf-table>
            <!-- </div> -->

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="confirm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

export default {
    name: "grid-select",
  
    props: {
        value: {
            type: [String, Number],
            default: () => {
                return null;
            },
        },
        label: {
            type: String,
            default: () => {
                return null;
            },
        },
        defaultLabel: {
            type: String,
            default: () => {
                return null;
            },
        },
        valueField: {
            type: String,
            default: () => {
                return "id";
            },
        },
        labelField: {
            type: String,
            default: () => {
                return "name";
            },
        },
        placeholder: {
            type: String,
            default: () => {
                return "请选择";
            },
        },
        size: {
            type: String,
            default: () => {
                return "medium";
            },
        },
        multiple: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        width: {
            type: String,
            default: () => {
                return "800px";
            },
        },
        searchItems: {
            type: Array,
            default: () => {
                return [
                    {
                        field: "id",
                        title: "id",
                        ctype: "input", // component type,  input dict dept
                        cvalue: undefined, // component value, dict type
                    },
                ];
            },
        },
        gridItems: {
            type: Array,
            default: () => {
                return [
                    {
                        field: "id",
                        title: "id",
                        ctype: undefined, // dict
                        cvalue: undefined, // colume value, dict type
                    },
                ];
            },
        },
        getDataList: {
            type: Function,
            default: null,
        },
    },
    data() {
        const tableColumn = [
            {
                type: this.multiple ? "checkbox" : "radio",
                width: 60,
                fixed: "left",
            },
        ];
        if (this.gridItems) {
            for (let i = 0; i < this.gridItems.length; i++) {
                const item = this.gridItems[i];
                if (item.type == "radio" || item.type == "checkbox") {
                    continue;
                }
                tableColumn.push(item);
                if (item.ctype == "dict") {
                    item.formatter = this.dictFormat;
                }
            }
        }
        const queryParams = {
            pageNum: 1,
            pageSize: 10,
        };
        if (this.searchItems) {
            for (let i = 0; i < this.searchItems.length; i++) {
                const item = this.searchItems[i];
                queryParams[item.field] = undefined;
            }
        }
        return {
            loading: false,
            total: 0,
            dataList: undefined,
            selectedValue: this.value,
            selectedLabel: this.label,
            visible: false,
            queryParams: queryParams,
            tableColumn: tableColumn,
            dicts: {},
        };
    },
    created() {},
    methods: {
        getList() {
            this.loading = true;
            this.getDataList(this.queryParams).then((response) => {
                this.loading = false;
                if (response.code == 0) {
                    this.dataList = response.data.list;
                    this.total = response.data.total;
                }
            });
        },
        dictFormat(e) {
            const col = this.tableColumn[e.columnIndex];
            if (col.cdata) {
                return this.selectDictLabel(col.cdata.values, e.cellValue);
            }
            return e.cellValue;
        },
        open() {
            if (this.gridItems) {
                for (let i = 0; i < this.gridItems.length; i++) {
                    const item = this.gridItems[i];
                    if (item.type == "radio" || item.type == "checkbox") {
                        continue;
                    }

                    if (item.ctype == "dict" && item.cvalue && !item.cdata) {
                        this.getDicts(item.cvalue).then((response) => {
                            if (response.code == 0) {
                                item.cdata = response.data;
                                this.dataList = [].concat(this.dataList);
                            }
                        });
                    }
                }
            }
            if (this.$refs.xTable) {
                this.selectedRows = undefined;
                this.$refs.xTable.clearRadioRow();
            }
            this.queryParams.pageNum = 1;
            this.resetQuery();
            this.visible = true;
        },
        confirm() {
            if (!this.selectedRows || this.selectedRows.length == 0) {
                this.$message.info("请选择");
                return;
            }

            this.selectedValue = this.selectedRows
                .map((v) => v[this.valueField])
                .join(",");
            if (this.labelField) {
                this.selectedLabel = this.selectedRows
                    .map((v) => v[this.labelField])
                    .join(",");
            } else {
                this.selectedLabel = undefined;
            }

            this.$emit("input", this.selectedValue);
            this.$emit("change", this.selectedValue, this.selectedRows);
            this.visible = false;
        },
        cancel() {
            this.visible = false;
        },
        clear() {
            this.selectedValue = undefined;
            this.selectedLabel = undefined;
            this.selectedRows = undefined;
            this.$emit("input", this.selectedValue);
            this.$emit("change", this.selectedValue);
        },
        handleQuery(e) {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        handlePageChange({ currentPage, pageSize }) {
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
        checkboxChangeEvent({ records }) {
            this.selectedRows = records;
        },
        radioChangeEvent({ newValue }) {
            this.selectedRows = [newValue];
        },
    },
    watch: {
        value(val) {
            this.selectedValue = val;
            if (!this.selectedValue) {
                this.selectedLabel = undefined;
            }
        },
        label(val) {
            this.selectedLabel = val;
        },
    },
};
</script>

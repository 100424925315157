import request from '@/utils/request'

// 查询文件列表
export function listFile(query) {
  return request({
    url: '/file/list',
    method: 'get',
    params: query
  })
}

// 查询文件详细
export function getFile(id) {
  return request({
    url: '/file/get?id=' + id,
    method: 'get'
  })
}

// 新增文件
export function addFile(data) {
  return request({
    url: '/file/add',
    method: 'post',
    data: data
  })
}

// 修改文件
export function updateFile(data) {
  return request({
    url: '/file/edit',
    method: 'post',
    data: data
  })
}

// 删除文件
export function delFile(id) {
  return request({
    url: '/file/del',
    method: 'post',
    data: {
      ids: id
    }
  })
}

// 上传文件
export function uploadFile(data) {
  return request({
    url: '/file/upload',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
import request from '@/utils/request'
import { tokenName } from '@/config'

export function login(data) {
    return request({
        url: '/auth/login',
        method: 'post',
        data
    })
}
// 获取验证码
export function getCodeImg() {
    return request({
      url: '/auth/captcha',
      method: 'get'
    })
}
export function tokenLogin(data) {
    return request({
        url: '/tokenLogin',
        method: 'post',
        data
    })
}

export function getUserInfo(accessToken) {
    return request({
        url: '/user/current',
        method: 'get',
        // data: {
        //     [tokenName]: accessToken,
        // },
    })
}

// 扫码登录
export function qrcodeLogin(device_id) {
    const data = {
      device_id
    }
    return request({
      url: '/auth/qrcodelogin/qrcode',
      method: 'post',
      data: data
    })
}
  
export function qrcodeLoginStatus(qrcode_id) {
    const data = {
      qrcode_id
    }
    return request({
      url: '/auth/qrcodelogin/status',
      method: 'post',
      data: data
    })
}
  
export function qrcodeLoginCancel(qrcode_id) {
    const data = {
      qrcode_id
    }
    return request({
      url: '/auth/qrcodelogin/cancel',
      method: 'post',
      data: data
    })
}
export function logout() {
    return request({
        url: '/auth/logout',
        method: 'post',
    })
}

export function register() {
    return request({
        url: '/register',
        method: 'post',
    })
}

export function oauthAuthorize(data) {
  return request({
      url: '/oauth/authorize',
      method: 'get',
      params: data
  })
}

// 返回provider对应的服务器登录地址
export function oauthLogin(provider, redirect_uri) {
  return request({
      url: '/oauth/login',
      method: 'post',
      data: {
        provider: provider,
        application: 'admin',
        userpool: 'admin',
        redirect_uri: redirect_uri
      }
  })
}

export function oauthLoginCallback(code, state) {
  return request({
      url: '/oauth/logincallback',
      method: 'post',
      data: {
        code: code,
        state: state
      }
  })
}

export function oauthTokenLogin(access_token) {
  return request({
      url: '/oauth/tokenlogin',
      method: 'post',
      data: {
        token: access_token
      }
  })
}

// 作为cas服务器登录返回ticket
export function casLogin(service) {
  return request({
      url: '/cas/login',
      method: 'get',
      params: {
        service: service
      }
  })
}

// 返回provider对应的服务器登录地址
export function casLoginUrl(provider, service) {
  return request({
      url: '/cas/loginurl',
      method: 'post',
      data: {
        provider: provider,
        application: 'admin',
        userpool: 'admin',
        service: service
      }
  })
}

export function casLoginCallback(provider, ticket, service) {
  return request({
      url: '/cas/logincallback',
      method: 'post',
      data: {
        provider: provider,
        application: 'admin',
        userpool: 'admin',
        ticket: ticket,
        service: service
      }
  })
}

<template>
  <div>
    <input ref="file" type="file" :accept="accept" style="display:none" @change="handleUpload" />
    <div v-if="type!='dialog'">
      <el-input v-model="inputValue" :placeholder="placeholder" :readonly="readonly" @change="handleChange">
        <el-button slot="append" icon="el-icon-upload" @click="handleClick"></el-button>
      </el-input>
    </div>
    <el-dialog v-else
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="visible"
      width="600px"
      append-to-body
    >
      <div>
        <el-input v-model="inputValue" :placeholder="placeholder" :readonly="readonly">
          <el-button slot="append" icon="el-icon-upload" @click="handleClick"></el-button>
        </el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { uploadFile } from "@/api/file/file";

export default {
  name: 'file-upload',
  props: {
    value: {
      type: String,
      default: () => { return null }
    },
    title: {
      type: String,
      default: () => { return '上传文件' }
    },
    placeholder: {
      type: String,
      default: () => { return '请上传文件' }
    },
    size: {
      type: String,
      default: () => { return 'medium' }
    },
    readonly: {
      type: Boolean,
      default: () => { return false }
    },
    accept: {
      type: String,
      default: () => { return '' }
    },
    type: {
      type: String,
      default: () => { return 'form' }
    },
    public: {
      type: String,
      default: () => { return "1" }
    }
  },
  data() {
    return {
      inputValue: this.value,
      visible: false,
    }
  },
  created() {
    if (this.inputValue) {

    }
  },
  methods: {
    open(val = '') {
      if (this.type == "dialog") {
        this.inputValue = val
      }
      this.visible = true;
    },
    confirm() {
      if (this.inputValue != this.value) {
        this.$emit('input', this.inputValue)
        this.$emit('change', this.inputValue)
      }
      this.visible = false;
    },
    cancel() {
      this.visible = false;
    },
    handleClick() {
      this.$refs.file.value = ""
      this.$refs.file.click()
    },
    handleUpload(e) {
      if (this.$refs.file.files.length > 0) {
        const file = this.$refs.file.files[0];
        if (this.accept) {
          let allow = false
          const sps = this.accept.split(',')
          for (let i = 0; i < sps.length; i++) {
            const s = sps[i].trim().toLowerCase()
            if (s == file.type) {
              allow = true
              break
            }
          }
          if (!allow) {
            return false
          }
        }
        let formData = new FormData();
        formData.append("file", file);
        formData.append("category", "default");
        formData.append("public", this.public)
        uploadFile(formData).then(response => {
          this.inputValue = window.location.origin + response.data.url;
          if (this.type != "dialog" && this.inputValue != this.value) {
            this.$emit('input', this.inputValue)
            this.$emit('change', this.inputValue)
          }
        }).catch(e => {
          console.log(e)
        })
      }
    },
    handleChange(e) {
      if (this.inputValue != this.value) {
        this.$emit('input', this.inputValue)
        this.$emit('change', this.inputValue)
      }
    }
  },
  watch: {
    value(val) {
      this.inputValue = val
    }
  }
}
</script>

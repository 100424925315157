import color from 'css-color-function'
import axios from 'axios'
import variables from '@/styles/variables.scss'

export const formula = {
    "primary": "color(primary)",
    "shade-1": "color(primary shade(10%))",
    "shade-2": "color(primary shade(20%))",
    "shade-3": "color(primary shade(30%))",
    "shade-4": "color(primary shade(40%))",
    "shade-5": "color(primary shade(50%))",
    "shade-6": "color(primary shade(60%))",
    "shade-7": "color(primary shade(70%))",
    "shade-8": "color(primary shade(80%))",
    "shade-9": "color(primary shade(90%))",
    "alpha-1": "color(primary alpha(.1))",
    "alpha-2": "color(primary alpha(.2))",
    "alpha-3": "color(primary alpha(.3))",
    "alpha-4": "color(primary alpha(.4))",
    "alpha-5": "color(primary alpha(.5))",
    "alpha-6": "color(primary alpha(.6))",
    "alpha-7": "color(primary alpha(.7))",
    "alpha-8": "color(primary alpha(.8))",
    "alpha-9": "color(primary alpha(.9))",
    "light-1": "color(primary tint(10%))",
    "light-2": "color(primary tint(20%))",
    "light-3": "color(primary tint(30%))",
    "light-4": "color(primary tint(40%))",
    "light-5": "color(primary tint(50%))",
    "light-6": "color(primary tint(60%))",
    "light-7": "color(primary tint(70%))",
    "light-8": "color(primary tint(80%))",
    "light-9": "color(primary tint(90%))"
  }

export function generateColors(primary) {
  let colors = {}

  Object.keys(formula).forEach((key) => {
    const value = formula[key].replace(/primary/g, primary)
    const c = color.convert(value)
    colors[key] = c.indexOf('rgba') > -1 ? c : colorRgbToHex(c)
  })

  return colors
}

/* 将rgb颜色转成hex */
export function colorRgbToHex(rgb) {
  let [r, g, b] = rgb.replace(/(?:\(|\)|rgb|RGB)*/g, '').split(',')

  return '#' + ((1 << 24) + (Number(r) << 16) + (Number(g) << 8) + Number(b)).toString(16).slice(1)
}




let originalStyle = ''

export function writeNewStyle(themeColor) {
  let colors = generateColors(themeColor)
  let cssText = originalStyle
  let colorsCssText = ''
  Object.keys(colors).forEach((key) => {
    cssText = cssText.replace(new RegExp('(:|\\s+)' + key, 'g'), '$1' + `${colors[key]}`)
    colorsCssText += `
      .color-${key}{color: ${colors[key]}!important;}
      .bg-${key}{background-color: ${colors[key]}!important;}
      .border-${key}{border-color: ${colors[key]}!important;}
    `
  })
  //操作document需要判断是独立运行还是在主应用中 运行 
  //qiankun 微前端框架基于 shadow dom 的形式生成子应用，主、子应用共用一个 document ，而 shadow dom 内的元素是不被暴露的。
  //在主应用内找到 shadowRoot 的父元素，利用 .shadowRoot 属性，创建子应用自己的 document
  let DOC = document.getElementById('themeStyle')
  if(!DOC) {
    //DOC = document.getElementById('mainContainer').firstChild.shadowRoot.getElementById('themeStyle')
    DOC = document.getElementById('mainContainer').firstChild.getElementById('themeStyle')

  }
  DOC.innerText = cssText + colorsCssText
}

// export function getIndexStyle() {
//   return new Promise((resolve) => {
//     if (!originalStyle) {
//       let indexCssFile = process.env.NODE_ENV === 'development' ? 'index' : 'indexPro'
//       axios.all([axios.get(`${process.env.VUE_APP_BASE_URL}theme/${indexCssFile}.css`), axios.get(`${process.env.VUE_APP_BASE_URL}themeData/extra.css`)]).then(
//         axios.spread((file, extraFile) => {
//           const fileData = file.data
//           const extraFileData = extraFile.data.replace(/[\r\n]/g, '')
//           originalStyle = getStyleTemplate(fileData + extraFileData)
//           resolve()
//         })
//       )
//     } else {
//       resolve()
//     }
//   })
// }

export function getIndexStyle() {
  return new Promise((resolve) => {
    if (!originalStyle) {
      let indexCssFile = process.env.NODE_ENV === 'development' ? 'index' : 'indexPro'
      axios.all([axios.get(`/theme/${indexCssFile}.css`), axios.get(`/themeData/extra.css`)]).then(
        axios.spread((file, extraFile) => {
          const fileData = file.data
          const extraFileData = extraFile.data.replace(/[\r\n]/g, '')
          originalStyle = getStyleTemplate(fileData + extraFileData)
          resolve()
        })
      )
    } else {
      resolve()
    }
  })
}


export function getStyleTemplate(data) {
  let colors = generateColors(variables.themeColor)
  const colorMap = new Map()
  Object.keys(formula).forEach((key) => {
    colorMap.set(colors[key], key)
  })

  for (let [key, value] of colorMap) {
    data = data.replace(new RegExp(key, 'ig'), value)
  }

  return data
}
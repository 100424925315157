import request from '@/utils/request'

// 获取路由
export const getRouters = (query) => {
  return request({
    url: '/router/list',
    method: 'get',
    params: query
  })
}
// 获取应用路由
export const getAppRouters = (query) => {
  return request({
    url: '/router/app/list',
    method: 'get',
    params: query
  })
}
// 获取我的收藏
export const getCollects = (query) => {
  return request({
    url: '/authcollect/my/list',
    method: 'get',
    params: query
  })
}

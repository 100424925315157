<template>
    <div class="sort-btn">
        <el-popover placement="bottom-end" width="400" v-model="visible">
            <div>
                <div class="font-size-20 mb-10">排序</div>
                <div class="column-list" v-if="filterActive.length">
                    <draggable v-model="filterActive">
                        <div
                            class="d-flex mb-10 align-items-center"
                            v-for="(item, index) in filterActive"
                            :key="index"
                        >
                            <div class="column-list-item flex-1">
                                <div class="flex-1" v-text="item.title"></div>

                                <el-radio
                                    class="mr-10"
                                    v-model="item.sort"
                                    label="asc"
                                    >升序</el-radio
                                >
                                <el-radio
                                    class="mr-10"
                                    v-model="item.sort"
                                    label="desc"
                                    >降序</el-radio
                                >
                                <i class="iconfont icon-apps icon-caiddang"></i>
                            </div>
                            <div
                                class="el-icon-remove-outline ml-10"
                                @click.stop="filterTypeChange(item, index, 2)"
                                style="font-size: 25px; z-index: 3001"
                            ></div>
                        </div>
                    </draggable>
                </div>

                <div class="filter-bottom" slot="popover">
                    <!-- <el-popover placement="bottom" width="260" v-model="typeVisible"> -->
                    <!-- </el-popover> -->
                    <!-- <div filter-type></div> -->
                    <div
                        class="filter-mask"
                        v-if="typeVisible"
                        @click="typeVisible = false"
                    ></div>
                    <div class="filter-type" v-if="typeVisible">
                        <el-input
                            placeholder="输入关键字"
                            v-model="filterKeywords"
                            suffix-icon="el-icon-search"
                        ></el-input>
                        <div class="filter-list">
                            <div
                                class="filter-list-item"
                                v-for="(item, index) in filterTypes"
                                :key="index"
                                :class="{
                                    active:
                                        isExist(filterActive, item.field) > -1,
                                }"
                                @click="filterTypeChange(item, index, 1)"
                            >
                                <div v-text="item.title"></div>
                                <i
                                    class="el-icon-success"
                                    v-if="
                                        isExist(filterActive, item.field) > -1
                                    "
                                ></i>
                            </div>
                        </div>
                    </div>
                    <div class="add-filter-type" @click="typeVisible = true">
                        <i
                            class="el-icon-circle-plus-outline add-filter-type-icon"
                        ></i>

                        添加排序规则
                    </div>

                    <div>
                        <el-button
                            size="mini"
                            type=""
                            @click="filterActive = []"
                            >清空
                        </el-button>
                        <el-button size="mini" type="primary" @click="onSubmit"
                            >确定
                        </el-button>
                    </div>
                </div>
            </div>
            <el-button class="btn-toolbar" slot="reference">
                <i
                    class="iconfont icon-paixu btn-toolbar-icon font-size-14"
                ></i>
                {{ btnText }}
            </el-button>
        </el-popover>
    </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
    components: {
        draggable,
    },
    props: {
        list: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            typeVisible: false,

            visible: false,

            filterKeywords: "",
            filterActive: [],

            // tableTitleColumn: [],
            calculationTypes: [
                {
                    value: ">",
                    lable: "大于",
                },
                {
                    value: "<",
                    lable: "小于",
                },
                {
                    value: "between",
                    lable: "介于",
                },
                {
                    value: "=",
                    lable: "大于",
                },
                {
                    value: 4,
                    lable: "大于等于",
                },
                {
                    value: 5,
                    lable: "小于等于",
                },
            ],
            filterList: [],
            btnText: "排序",
        };
    },
    computed: {
        filterTypes: function () {
            return this.filterList.filter(
                (o) =>
                    o.title &&
                    o.title !== "操作" &&
                    o.title.indexOf(this.filterKeywords) !== -1
            );
        },
    },
    watch: {
        list: {
            handler(val) {
                this.filterList = this.deepCopy(this.list);
            },
            //此属性是控制是否首次加载就会执行，如果不写immediate，或者写immediate：false，
            //两者相同都是首次加载不会执行
            immediate: true,
            //deep:true,深度监听，可以监听对象属性
            deep: true,
        },
    },
    methods: {
        onSubmit() {
            let obj = {};
            this.filterActive.forEach((o) => {
                obj[o.field] = o.sort;
                // list.push(obj)
            });
            this.typeVisible = false;
            this.visible = false;
            this.btnText =
                this.filterActive.length > 0
                    ? this.filterActive.length + "项"
                    : "排序";
            this.$emit("change", { data: obj, name: "srot" });
        },
        isExist(list, field) {
            return list.findIndex((o) => o.field === field);
        },
        filterTypeChange(row, index, type) {
            if (type === 2) {
                this.filterActive.splice(index, 1);
                return false;
            }
            let i = this.isExist(this.filterActive, row.field);
            if (i === -1) {
                let obj = this.deepCopy(row);
                obj.sort = "asc";
                this.filterActive.push(obj);
                return false;
            }
            this.filterActive.splice(i, 1);
        },
    },
};
</script>

<style lang="scss" scoped>
.sort-btn {
    display: inline-block;
    // float: right;
}

.text-primary {
    color: #1890ff;
}

.d-flex {
    display: flex;
}

.flex {
    &-1 {
        flex: 1;
    }

    &-2 {
        flex: 2;
    }

    &-3 {
        flex: 3;
    }
}

.font-size-20 {
    font-size: 20px;
}

.text-center {
    text-align: center;
}

.align-items-center {
    align-items: center;
}

.ml-10 {
    margin-left: 10px;
}

.mr-10 {
    margin-right: 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.filter-list {
    padding-top: 10px;
    max-height: 200px;
    overflow-y: auto;

    &-item {
        padding: 0 10px;
        height: 35px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 5px 0;
        cursor: pointer;
        border-radius: 5px;
        color: rgba(0, 0, 0, 0.55);

        &:hover {
            background: rgba(24, 144, 255, 0.2);
            color: #1890ff;
        }

        &.active {
            background: rgba(24, 144, 255, 0.2);
            color: #1890ff;
        }
    }
}

.filter-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    position: relative;

    .filter-mask {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 3000;
    }

    .filter-type {
        position: absolute;
        background-color: #fff;
        top: 20px;
        border-radius: 10px;
        padding: 10px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        z-index: 3001;
    }

    .add-filter-type {
        color: #1890ff;
        display: flex;
        cursor: pointer;
        align-items: center;
        line-height: 25px;

        &-icon {
            font-size: 25px;
            margin-right: 5px;
        }
    }
}

.btn-toolbar {
    // float: right;
    border: none !important;
    margin: 0 12px 0 0 !important;
    padding: 0 0.5em !important;
    height: 32px;
    line-height: 32px;

    &-icon {
        margin-right: 5px;
    }
}

.sort-btn .btn-toolbar:hover,
.sort-btn .btn-toolbar:focus {
    background: transparent !important;
}

.sort-btn .vxe-tools--wrapper {
    .vxe-button {
        border: none !important;
        margin: 0 12px 0 0 !important;
        padding: 0 0.5em !important;
    }
}

.column-list {
    padding: 15px;
    max-height: 300px;
    overflow-y: auto;

    &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        line-height: 1;
        padding: 10px;
        // margin-top: 10px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        background: #fff;

        // &:hover {
        // 	border: 1px solid #1890ff;
        // 	color: #1890ff
        // }
    }
}

.sortable-ghost {
    background: #fff !important;
    // opacity: 0 !important;
}

.sortable-chosen {
    .icon-caiddang {
        color: #1890ff;
    }

    background: #fff !important;
    // border: 1px solid #1890ff;
    opacity: 1 !important;

    // .column-list-item {
    // 	border: 1px solid #1890ff;
    // 	color: #1890ff
    // }
}
</style>
<template>
  <div :class="'el-input el-input--' + size + (size == 'small' ? ' el-input--suffix' : '') + (disabled ? ' is-disabled' : '')">
    <input class="el-input__inner" :style="'padding-right: 68px; overflow: hidden; text-overflow: ellipsis;'" readonly="readonly" :placeholder="placeholder" @click="handleInputClick" :disabled="disabled" :value="value" />
    <el-button v-if="value && !disabled" :style="'position: absolute; right: 44px; top: ' + (size == 'small' ? 1 : 0) + 'px; height: ' + (size == 'small' ? 32 : 36) + 'px; padding: 9px 12px; width: 24px;'" type="text" icon="el-icon-close" size="small" @click="handleClear"></el-button>
    <el-button :style="'position: absolute; right: 0px; top: ' + (size == 'small' ? 1 : 0) + 'px; height: ' + (size == 'small' ? 32 : 36) + 'px; padding: 9px 12px; border-top-left-radius: 0px; border-bottom-left-radius: 0px;'" type="primary" icon="el-icon-search" size="small" @click="handleClick" :disabled="disabled"></el-button>
  </div>
</template>
<script>
export default {
  name: 'input-select-button',
  props: {
    value: {
      type: [String, Number],
      default: () => { return null }
    },
    placeholder: {
      type: String,
      default: () => { return '请选择' }
    },
    size: {
      type: String,
      default: () => { return 'medium' }
    },
    disabled: {
      type: Boolean,
      default: () => { return false }
    }
  },
  methods: {
    handleInputClick() {
      //this.$emit('click')
    },
    handleClick() {
      this.$emit('click')
    },
    handleClear() {
      this.$emit('clear')
    }
  }
}
</script>

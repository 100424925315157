import request from '@/utils/request'

export function listApplication(query) {
  return request({
    url: '/application/list/' + query.type,
    method: 'get',
    params: query
  })
}

export function marketApplication(query) {
  return request({
    url: '/application/market',
    method: 'get',
    params: query
  })
}

export function getApplication(id) {
  return request({
    url: '/application/get?id=' + id,
    method: 'get'
  })
}

export function addApplication(data) {
  return request({
    url: '/application/add',
    method: 'post',
    data: data
  })
}

export function editApplication(data) {
  return request({
    url: '/application/edit',
    method: 'post',
    data: data
  })
}

export function delApplication(ids) {
  return request({
    url: '/application/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

export function publishApplication(id, publish) {
  return request({
    url: '/application/publish',
    method: 'post',
    data: {
      id: id,
      publish: publish
    }
  })
}

export function getApplicationConfig(name) {
  return request({
    url: '/application/config',
    method: 'get',
    params: {
      userpool: process.env.VUE_APP_USERPOOL,
      name: name,
    }
  })
}

export function applicationIncViews(id) {
  return request({
    url: '/application/incviews',
    method: 'post',
    data: {
      id: id
    }
  })
}
/**
 * @description 路由拦截状态管理，目前两种模式：all模式与intelligence模式，其中partialRoutes是菜单暂未使用
 */
import {
	asyncRoutes,
	baseRoutes
} from '@/router'
import {
	getRouters,
	getCollects
} from '@/api/menu'
import {
	convertRouter,
	filterAsyncRoutes
} from '@/utils/handleRoutes'
import { getApplication } from '@/utils/application'

const state = () => ({
	routes: [],
	partialRoutes: [],
	addRoutes: [],
	appRoutes: [],
	mainRoutes: [],
	allRoutes: baseRoutes,
	currentRoute: undefined,
	rootRoute: undefined,
	collects: [],
	breadcrumbList: []
})
const getters = {
	routes: (state) => state.routes,
	mainRoutes: (state) => state.mainRoutes,
	partialRoutes: (state) => state.partialRoutes,
	currentRoute: (state) => state.currentRoute,
	rootRoute: (state) => state.rootRoute,
	collects: state => state.collects,
	breadcrumbList: state => state.breadcrumbList,
}
const mutations = {
	setCollects: (state, collects) => {
		state.collects = collects
	},
	setRoutes(state, routes) {
		state.routes = baseRoutes.concat(routes)
	},
	setCurrentRoute: (state, route) => {
		if (route) {
			state.currentRoute = route.current
			state.rootRoute = route.root
		} else {
			state.currentRoute = undefined
			state.rootRoute = undefined
		}
	},
	setAllRoutes(state, routes) {
		state.addRoutes = routes
		state.routes = routes.concat(baseRoutes)
		for (let key in state.routes) {
			let item = state.routes[key];
			if (item.is_app == "1") {
				state.appRoutes.push(item)
			}
			if (item.is_main == "1") {
				state.mainRoutes.push(item)
			}
		}
     
	},
	setPartialRoutes(state, routes) {
		state.partialRoutes = baseRoutes.concat(routes)
	},
	setBreadcrumbList(state, routerList) {
		state.breadcrumbList = routerList
	}
}
const actions = {
	async setRoutes({commit}, permissions) {
		//开源版只过滤动态路由permissions，admin不再默认拥有全部权限
		const finallyAsyncRoutes = await filterAsyncRoutes(
			[...asyncRoutes],
			permissions
		)
		commit('setRoutes', finallyAsyncRoutes)
		return finallyAsyncRoutes
	},
	setCurrentRoute({commit}, route) {
		commit('setCurrentRoute', route)
	},
	setCollects({commit}, collects) {
		commit('setCollects', collects)
	},
	async setAllRoutes({commit}) {
		// getCollects().then(res => {
		//   commit('SET_COLLECTS', res.data.list)
		//   resolve(res.data)
		// })
		// let { data } = await getRouters()
		// // let data = baseRoutes;
		// data.push({ path: '*', redirect: '/404', hidden: true })
		// let accessRoutes = convertRouter(data)
		// commit('setAllRoutes', accessRoutes)
		// return accessRoutes
		return new Promise(resolve => {
			// 向后端请求收藏数据
			getCollects().then(res => {
				commit('setCollects', res.data.list)
				resolve(res.data)
			})
		}).then(function(result) {
			return new Promise(resolve => {
				// 向后端请求路由数据
				getRouters({application: getApplication()}).then(res => {
					const accessedRoutes = convertRouter(res.data)
					accessedRoutes.push({
						path: '*',
						redirect: '/404',
						hidden: true
					})
					commit('setAllRoutes', accessedRoutes)
					resolve(accessedRoutes)
				})
			})
		})
	},
	setPartialRoutes({commit}, accessRoutes) {
		commit('setPartialRoutes', accessRoutes)
		return accessRoutes
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
/**
 * @description 导出默认主题配置
 */
const theme = {
  //是否国定头部 固定fixed 不固定noFixed
  header: 'fixed',
  //横纵布局 横向布局：horizontal 纵向布局：vertical  分栏：column 综合布局：comprehensive
  layout: 'column',
  //主题风格 清新light 深色dark 
  themeMode: 'light',
  //主题 蓝白 绿白...
  //主题颜色
  themeColor: '#1890ff',
  //是否开启主题配置按钮
  themeBar: true,
  //是否显示多标签页
  tabsBar: true,
  //当前主题抽屉是否打开
  themeDrawerVisible: false
}
module.exports = theme

<template>
    <div style="display: flex; flex-direction: column;">
        <slot name="top"></slot>
        <div style="display: flex; flex-direction: row;">
            <slot name="left"></slot>
            <div style="flex: 1">
                <el-form ref="form" :model="model" :rules="rules" :label-width="labelWidth" :style="readonly ? 'display: none' : ''">
                    <slot></slot>
                </el-form>
                <detail-form v-if="readonly" :data="data" :column="readonlyColumn" :labelStyle="labelWidth ? ('min-width: ' + labelWidth) : ''">
                    <template v-for="(item, index) in data" v-slot:[item.slots]>
                        <slot :name="item.slots"></slot>
                    </template>
                </detail-form>
            </div>
            <slot name="right"></slot>
        </div>
        <slot name="bottom"></slot>
    </div>
</template>

<script>
export default {
    name: 'jf-form',
    props: {
        model: {
            type: Object,
            default: undefined
        },
        rules: {
            type: Object,
            default: undefined
        },
        labelWidth: {
            type: String,
            default: undefined
        },
        readonly: {
            type: Boolean,
            default: false
        },
        readonlyColumn: {
            type: Number,
            default: 2
        },
        readonlyFilter: {
            type: Function
        }
    },
    data() {
        return {
            data: []
        }
    },
    mounted() {
        this.refresh()
    },
    methods: {
        validate(f) {
            this.$refs.form.validate(f)
        },
        resetFields() {
            this.$refs.form.resetFields()
        },
        refresh() {
            if (!this.$refs.form) {
                return
            }
            const data = []
            this.listChildren(this.$refs.form.$children, (c) => {
                if (!c.$el || !c.$el.parentNode || !c.$el.parentNode.parentNode) {
                    // v-hasPermi
                    return
                }
                if (this.readonlyFilter) {
                    const ret = this.readonlyFilter(c)
                    if (ret) {
                        data.push(ret)
                        return
                    }
                }
                const tag = c.$vnode.tag
                if (tag.indexOf('-ElFormItem') > 0) {
                    let value = ''
                    let color = ''
                    let isArray = false
                    for (let i = 0; i < c.$children.length; i++) {
                        let c1 = c.$children[i]
                        const tag = c1.$vnode.tag
                        if (tag.indexOf('-ElInput') > 0) {
                            value = c1.value
                        } else if (tag.indexOf('-ElSelect') > 0) {
                            isArray = c1.multiple || Array.isArray(c1.selected)
                            if (isArray) {
                                for (let j = 0; j < c1.selected.length; j++) {
                                    const s = c1.selected[j]
                                    if (value != '') {
                                        value += ','
                                    }
                                    value += s.label
                                }
                            } else {
                                value = c1.selectedLabel
                            }
                        } else if (tag.indexOf('-ElRadioGroup') > 0) {
                            this.listChildren(c1.$children, (c2) => {
                                if (c2.$vnode.tag.indexOf('-ElRadio') > 0) {
                                    if (c1.value == c2.label) {
                                        value = c2.$slots && c2.$slots.default && c2.$slots.default.length > 0 && c2.$slots.default[0].text || c2.label
                                    }
                                }
                            })
                        } else if (tag.indexOf('-ElCheckboxGroup') > 0) {
                            const vt = typeof c1.value
                            this.listChildren(c1.$children, (c2) => {
                                isArray = true
                                if (c2.$vnode.tag.indexOf('-ElCheckbox') > 0) {
                                    if (vt != 'object') {
                                        if (c1.value == c2.label) {
                                            value = c2.$slots && c2.$slots.default && c2.$slots.default.length > 0 && c2.$slots.default[0].text || c2.label
                                        }
                                    } else {
                                        for (let j = 0; j < c1.value.length; j++) {
                                            if (c1.value[j] == c2.label) {
                                                if (value != '') {
                                                    value += ','
                                                }
                                                value += c2.$slots && c2.$slots.default && c2.$slots.default.length > 0 && c2.$slots.default[0].text || c2.label
                                            }
                                        }
                                    }
                                }
                            })
                        } else if (tag.indexOf('-ElSwitch') > 0) {
                            value = c1.value == c1.activeValue ? (c1.activeText || '是') : (c1.inactiveText || '否')
                        } else if (tag.indexOf('-ElColorPicker') > 0) {
                            value = c1.value
                            color = c1.value
                        } else if (tag.indexOf('-ElDatePicker') > 0) {
                            if (c1.type == 'daterange') {
                                if (c1.displayValue && c1.displayValue.length > 0) {
                                    value = c1.displayValue.join(' 至 ')
                                }
                            } else {
                                value = c1.displayValue
                            }
                        } else if (tag.indexOf('-ElTimePicker') > 0) {
                            if (c1.isRange) {
                                if (c1.displayValue && c1.displayValue.length > 0) {
                                    value = c1.displayValue.join(' 至 ')
                                }
                            } else {
                                value = c1.displayValue
                            }
                        } else if (tag.indexOf('-ElTimeSelect') > 0) {
                            value = c1.displayValue
                        } else if (tag.indexOf('-treeselect') > 0) {
                            this.listDept(c1.options, (d) => {
                                if (d.id == c1.value) {
                                    value = d.label || d.name
                                }
                            })
                        } else if (tag.indexOf('-admin-selectex') > 0) {
                            if (!c1.nickname) {
                                if (this.nicknameTimerId) {
                                    clearInterval(this.nicknameTimerId)
                                }
                                const c1t = c1
                                this.nicknameTimerId = setInterval(() => {
                                    if (c1t.nickname) {
                                        clearInterval(this.nicknameTimerId)
                                        this.nicknameTimerId = undefined
                                        this.refresh()
                                    }
                                }, 100)
                            }
                            value = c1.label || c1.nickname
                            isArray = true
                        } else if (tag.indexOf('-dict-select') > 0) {
                            if (!c1.dictData || Object.keys(c1.dictData).length == 0) {
                                if (this.dictDataTimerId) {
                                    clearInterval(this.dictDataTimerId)
                                }
                                const c1t = c1
                                this.dictDataTimerId = setInterval(() => {
                                    if (c1t.dictData && Object.keys(c1t.dictData).length > 0) {
                                        clearInterval(this.dictDataTimerId)
                                        this.dictDataTimerId = undefined
                                        this.refresh()
                                    }
                                }, 100)
                            }
                            c1 = c1.$children[0]
                            isArray = c1.multiple || Array.isArray(c1.selected)
                            if (isArray) {
                                for (let j = 0; j < c1.selected.length; j++) {
                                    const s = c1.selected[j]
                                    if (value != '') {
                                        value += ','
                                    }
                                    value += s.label
                                }
                            } else {
                                value = c1.selectedLabel
                            }
                        } else if (tag.indexOf('-datetime-select') > 0) {
                            value = c1.$children[0].displayValue
                        } else {
                            //console.log(tag)
                        }
                    }
                    let icon = ''
                    let slots = ''
                    let span = undefined
                    if (c.$parent.$vnode.tag.indexOf('-jf-form-item') > 0) {
                        if (!value) {
                            value = c.$parent.value
                        }
                        icon = c.$parent.icon
                        slots = c.$parent.slots
                        span = c.$parent.span
                    }
                    if (isArray && value) {
                        if (value.indexOf(',') > 0) {
                            value = value.split(',')
                        } else {
                            value = [value]
                        }
                    }
                    data.push({
                        label: c._props.label,
                        value: value,
                        color: color,
                        icon: icon,
                        slots: slots,
                        span: span
                    })
                } else {
                    //console.log(tag)
                }
            })
            this.data = data
        },
        listChildren(children, f) {
            if (!children) {
                return
            }
            for (let i = 0; i < children.length; i++) {
                const c = children[i]
                f(c)
                if (c.$children) {
                    this.listChildren(c.$children, f)
                }
            }
        },
        listDept(children, f) {
            if (!children) {
                return
            }
            for (let i = 0; i < children.length; i++) {
                const c = children[i]
                f(c)
                if (c.children) {
                    this.listDept(c.children, f)
                }
            }
        },
    },
    watch: {
        model() {
            this.$nextTick(() => {
                this.refresh()
            })
        }
    }
}
</script>
import Vue from 'vue'
import axios from 'axios'
import Qs from 'qs'
import { Base64 } from 'js-base64'
import { Notification, MessageBox, Message } from 'element-ui'
import store from '@/store'
import router from '@/router'
import errorCode from '@/utils/errorCode'
import {
    baseURL,
    contentType,
    debounce,
    invalidCode,
    noPermissionCode,
    requestTimeout,
    successCode,
    tokenName,
    loginInterception
} from '@/config'

import { isArray } from "@/utils/validate";

let loadingInstance

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'


/**
 * @description 处理code异常
 * @param {*} code
 * @param {*} msg
 */
const handleCode = (code, msg) => {
    switch (code) {
        case invalidCode:
            //登录失效
            Vue.prototype.$baseMessage(msg || `后端接口${code}异常`, 'error')
            store.dispatch('user/resetAccessToken').catch(() => { })
            if (loginInterception) { //如果开启了登录拦截，重新加载页面
                location.reload()
            }
            break
        case noPermissionCode:
            //无权限
            MessageBox.confirm(
                '登录状态已过期，您可以继续留在该页面，或者重新登录',
                '提示',
                {
                    confirmButtonText: '重新登录',
                    cancelButtonText: '取消',
                    type: 'warning',
                    closeOnClickModal:false
                }
            ).then(() => {
                store.dispatch('user/logout')
            })
            break
        default:
            Vue.prototype.$baseMessage(msg || `后端接口${code}异常`, 'error')
            break
    }
}
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: baseURL,
    // 超时
    timeout: requestTimeout,
    headers: {
        'Content-Type': contentType
    }
})
// request拦截器
service.interceptors.request.use(config => {
    // 是否需要设置 token
    if(!('needToken' in config) || config.needToken){
        //不存在或者为false
        if (store.getters['user/accessToken']) {
            config.headers['Authorization'] = store.getters['user/accessToken']
        }
    }

    if (config.data) {
        const contentType = config.headers['Content-Type']
        if (contentType === 'application/x-www-form-urlencoded') {
            config.data = Qs.stringify(config.data)
        } else if (contentType.indexOf('application/json') >= 0) {
            if (process.env.VUE_APP_REQUEST_JSON_BODY_ENCRYPT === 'base64') {
                config.data = {
                    encrypt_type: 'base64',
                    encrypt_data: Base64.encode(JSON.stringify(config.data))
                }
            }
        }

    }
    //检测需要加loading层的请求，防止重复提交
    if (debounce.some((item) => config.url.includes(item))) {
        console.log(`[micro-app1] 需要加loading层的请求，防止重复提交`);
        loadingInstance = Vue.prototype.$baseLoading()
    }
    return config
}, error => {
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(
    (response) => {
        //console.log(response)
        if (response.headers['content-type'].indexOf('application/json') >= 0) {
            if (process.env.VUE_APP_RESPONSE_JSON_BODY_ENCRYPT && response.data && response.data.encrypt_type && response.data.encrypt_data) {
                if (response.data.encrypt_type == 'base64') {
                    response.data = JSON.parse(Base64.decode(response.data.encrypt_data))
                }
            }
        }

        if (loadingInstance) loadingInstance.close()

        const { data, config } = response
        const { code, msg } = data
        // 操作正常Code数组
        const codeVerificationArray = isArray(successCode)
            ? [...successCode]
            : [...[successCode]]
        // 是否操作正常
        if (codeVerificationArray.includes(code)) {
            return data
        } else {
            if(config.url.indexOf("/group1") == -1){
                handleCode(code, msg)
                return Promise.reject(
                    '请求异常拦截:' +
                    JSON.stringify({ url: config.url, code, msg }) || 'Error'
                )
            }else {
                return data
            }
        }
    },
    (error) => {
        if (loadingInstance) loadingInstance.close()
        const { response, message } = error
        if (error.response && error.response.data) {
            const { status, data } = response

            //文件服务接口认证不通过特殊处理
            if("auth fail"!=error.response.data.message){
                handleCode(status, data.msg || message)
            }else {
                Vue.prototype.$baseMessage(`文件服务权限接口认证不通过: `+error.response.data.message)
            }

            return Promise.reject(error)
        } else {
            let { message } = error
            if (message === 'Network Error') {
                message = '网络链接异常'
            }
            if (message.includes('timeout')) {
                message = '网络请求超时'
            }
            if (message.includes('Request failed with status code')) {
                const code = message.substr(message.length - 3)
                message = '服务器' + code + '异常'
            }
            Vue.prototype.$baseMessage(message || `服务器未知异常`, 'error')
            return Promise.reject(error)
        }
    }
)

export default service
/**
 * 路由守卫 统一处理页面跳转的一些逻辑
 * 比如增加页面加载进度条 / token失效跳转登录等
 */
import router from "@/router"
import store from "@/store"
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import getPageTitle from '@/utils/pageTitle'
import recursiveTreeByLastLevel from '@/utils/handleRoutes'
import {isChinese} from '@/utils/validate'
import {
	Loading
} from 'element-ui';
import {
	authentication,
	loginInterception,
	progressBar,
	recordRoute,
	routesWhiteList,
} from '@/config'
import {
	resetRouter
} from "../router"
import {
	casLoginUrl
} from '@/api/user';

function doCasLogin(next) {
	const service = window.location.origin + "/#/cas/callback";
	if (process.env.VUE_APP_CAS_SERVER_URL) {
		const loginUrl = `${process.env.VUE_APP_CAS_SERVER_URL}/login?service=${encodeURIComponent(service)}`;
		window.location.href = loginUrl;
	} else {
		const loading = Loading.service({
			lock: true,
			text: '请稍候...',
			spinner: 'el-icon-loading',
			background: 'rgba(0, 0, 0, 0.7)'
		});
		casLoginUrl("cas", service).then(res => {
			loading.close();
			window.location.href = res.data.login_url;
		}).catch(e => {
			loading.close();
			console.log(e)
			//next('/login') // 会死循环
		})
	}
}



NProgress.configure({
	easing: 'ease',
	speed: 500,
	trickleSpeed: 200,
	showSpinner: false
})

router.beforeResolve(async (to, from, next) => {
	if (progressBar) NProgress.start()
    if (to.matched && to.matched.length > 2) {
		to.matched.splice(1, to.matched.length - 2)
	}
	
  if (window.location.href.indexOf('/oauth/callback') >= 0 && to.path.indexOf('/oauth/callback') <
    0) {
    next('/oauth/callback')
    if (progressBar) NProgress.done()
    return
  }

  if (window.location.href.indexOf('/oauth/ybcallback') >= 0 && to.path.indexOf('/oauth/ybcallback') <
    0) {
    next('/oauth/ybcallback')
    if (progressBar) NProgress.done()
    return
  }

  if (window.location.href.indexOf('/cas/callback') >= 0 && to.path.indexOf('/cas/callback') <
    0) {
    next('/cas/callback')
    if (progressBar) NProgress.done()
    return
  }

	let hasToken = store.getters['user/accessToken']
	//console.log('/hasToken///', hasToken)
	if (hasToken) {
		if (window.location.href.indexOf('/oauth/authorize') >= 0 && to.path.indexOf('/oauth/authorize') <
			0) {
			next('/oauth/authorize')
			if (progressBar) NProgress.done()
			return
		}

		if ((window.location.href.indexOf('/cas?service=') >= 0 || window.location.href.indexOf(
				'/cas/login?service=') >= 0) && to.path.indexOf('/cas/login') < 0) {
			next('/cas/login')
			if (progressBar) NProgress.done()
			return
		}

		//有token时禁止进入login页面
		if (to.path === '/login') {
			next({
				path: '/'
			})
			if (progressBar) NProgress.done()
		} else {
			//判断权限
			if (progressBar) NProgress.done()
			let menus = store.getters['routes/routes'] || []
			if (menus.length > 0) {
				// next()
        //          if (hasPermission(store.getters.roles, to.meta.roles)) {
        //   next()
        // } else {
        //   next({ path: '/401', replace: true, query: { noGoBack: true }})
        // }
			} else {
				await store.dispatch('user/getUserInfo').then(res => {
					store.dispatch('routes/setAllRoutes').then(accessRoutes => {
						router.addRoutes(accessRoutes) // 动态添加可访问路由表
                        next({
							...to,
							replace: true
						})
					})
				})

			}
			next()
		}
	} else {
		if (routesWhiteList.indexOf(to.path) !== -1) {
			if (to.path.indexOf('/login') == 0 && process.env.VUE_APP_ENABLE_CAS === 'true') {
				doCasLogin(next)
			} else {
				next()
			}
		} else {
			if (process.env.VUE_APP_ENABLE_CAS === 'true') {
				doCasLogin(next)
			} else if (recordRoute) {
				next(`/login?redirect=${to.path}`)
			} else {
				next('/login')
			}

			if (progressBar) NProgress.done()
		}
	}
	//修改页面标题
	document.title = getPageTitle(to.meta.title)
})

//全局后置钩子
// router.afterEach((to, from) => {
// 	if (progressBar) NProgress.done()
// })
router.afterEach((to, from, next) => {
	var routerList = recursiveTreeByLastLevel(to.name, store.state.routes.routes, 'name')
	store.commit('routes/setBreadcrumbList', routerList) // 经过vuex缓存
	if (progressBar) NProgress.done()
})

router.beforeEach((to, from, next) => {
    // 检查路由是否存在，如果不存在，跳转到404页面
    if (!isChinese(to.name)) {
      next('/404'); // 跳转到404页面
    } else {
      next(); // 继续正常导航
    }
  })

// router.beforeResolve((to, from, next) => {
	
// })
<template>
  <div>
    <slot>
      <input-select-button :value="label || selectedLabel || (selectedValue && defaultLabel) || selectedValue || ''" 
        :placeholder="placeholder"
        :size="size"
        :disabled="disabled"
        @clear="clear" 
        @click="open" 
      />
    </slot>

    <el-dialog
      :close-on-click-modal="false"
      :title="placeholder"
      :visible.sync="visible"
      width="600px"
      append-to-body
    >
      <div>
        <el-input
          placeholder="输入关键字进行过滤"
          v-model="filterText"
          clearable
        >
        </el-input>

        <el-tree
          class="filter-tree"
          :node-key="valueField"
          :data="dataTree"
          :props="defaultProps"
          :default-expand-all="false"
          :default-expanded-keys="defaultExpandedKeys"
          :expand-on-click-node="false"
          :filter-node-method="filterNode"
          :show-checkbox="true"
          :check-on-click-node="true"
          :check-strictly="checkStrictly"
          @check="handleCheck"
          ref="tree">
        </el-tree>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'tree-select',
  props: {
    value: {
      type: [String, Number],
      default: () => { return null }
    },
    label: {
      type: String,
      default: () => { return null }
    },
    defaultLabel: {
      type: String,
      default: () => { return null }
    },
    valueField: {
      type: String,
      default: () => { return 'id' }
    },
    labelField: {
      type: String,
      default: () => { return 'name' }
    },
    idField: {
      type: String,
      default: () => { return 'id' }
    },
    parentField: {
      type: String,
      default: () => { return 'parent_id' }
    },
    childrenField: {
      type: String,
      default: () => { return 'children' }
    },
    placeholder: {
      type: String,
      default: () => { return '请选择' }
    },
    size: {
      type: String,
      default: () => { return 'medium' }
    },
    disabled: {
      type: Boolean,
      default: () => { return false }
    },
    multiple: {
      type: Boolean,
      default: () => { return false }
    },
    getDataList: {
      type: Function,
      default: null
    },
    dataFormatter: {
      type: Function,
      default: null
    },
    rootOnly: {
      Type: Boolean,
      default: () => { return false }
    },
    checkStrictly: {
      Type: Boolean,
      default: () => { return true }
    }
  },
  data() {
    return {
      dataTree: undefined,
      selectedValue: this.value,
      selectedLabel: this.label,
      visible: false,
      filterText: '',
      defaultProps: {
        children: this.childrenField,
        label: this.labelField
      },
      defaultExpandedKeys: [],
    }
  },
  created() {
    if (this.selectedValue) {
      this.getTree()
    }
  },
  methods: {
    getTree() {
      if (this.dataTree) {
        this.initData()
        return
      }

      this.getDataList().then(response => {
        if (response.code == 0) {
          if (this.dataFormatter) {
            this.dataTree = this.dataFormatter(response.data.list)
          } else {
            this.dataTree = this.handleTree(response.data.list, this.idField, this.parentField);
          }
          if (this.rootOnly) {
            for (let i = 0; i < this.dataTree.length; i++) {
              this.dataTree[i][this.childrenField] = undefined
            }
          }
          this.initData()
        }
      });
    },
    initData() {
      if (!this.dataTree) {
        return
      }

      for (let i = 0; i < this.dataTree.length; i++) {
        this.defaultExpandedKeys.push(this.dataTree[i][this.valueField])
      }
      
      if (this.selectedValue) {
        this.selectedLabel = this.getLabelByValue(this.selectedValue)
      }
    },
    getNodeByKey(nodes, key) {
      if (!nodes) {
        return null
      }
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i]
        if (node[this.valueField] == key) {
          return node
        } else if (node.children) {
          const n = this.getNodeByKey(node.children, key)
          if (n) {
            return n
          }
        }
      }

      return null
    },
    getLabelByValue(value) {
      if (this.multiple) {
        const selectedValues = value.split(',')
        let selectedLabels = ''
        for (let i = 0; i < selectedValues.length; i++) {
          const node = this.getNodeByKey(this.dataTree, selectedValues[i])
          if (node) {
            if (selectedLabels != '') {
              selectedLabels += ','
            }
            selectedLabels += node[this.labelField]
          }
        }
        return selectedLabels
      } else {
        const node = this.getNodeByKey(this.dataTree, value)
        if (node) {
          return node[this.labelField]
        }
        return ''
      }
    },
    open(update) {
      if (update) {
        this.dataTree = undefined
      }
      if (this.$refs.tree) {
        this.selectedKey = undefined
        this.$refs.tree.setCheckedKeys([], true)
      }
      this.getTree()
      this.visible = true;
    },
    confirm() {
      if (this.selectedKey === undefined) {
        this.$message.info('请选择')
        return
      }
      if (this.selectedKey) {
        this.selectedValue = this.selectedKey + ''
        this.selectedKey = undefined
        this.selectedLabel = this.getLabelByValue(this.selectedValue)
        this.$emit('input', this.selectedValue)
        this.$emit('change', this.selectedValue)
      }
      this.visible = false;
    },
    cancel() {
      this.visible = false;
    },
    clear() {
      this.selectedValue = undefined
      this.selectedLabel = undefined
      this.selectedKey = undefined
      this.$emit('input', this.selectedValue)
      this.$emit('change', this.selectedValue)
    },
    filterNode(value, data) {
      if (!value) return true;
      return data[this.labelField].indexOf(value) !== -1;
    },
    handleCheck(e1, e2) {
      if (this.multiple) {
        this.selectedKey = e2.halfCheckedKeys.concat(e2.checkedKeys).join(',')
      } else {
        if (this.selectedKey && this.selectedKey == e1[this.valueField]) {
          this.selectedKey = undefined
          this.$refs.tree.setCheckedKeys([], true)
        } else {
          this.selectedKey = e1[this.valueField]
          this.$refs.tree.setCheckedKeys([e1[this.valueField]], true)
        }
      }
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    value(val) {
      this.selectedValue = val
      if (this.selectedValue) {
        this.selectedLabel = this.getLabelByValue(this.selectedValue)
      } else {
        this.selectedLabel = undefined
      }
    },
    label(val) {
      this.selectedLabel = val
    }
  }
}
</script>

<style scoped>
.filter-tree {
  margin-top: 10px;
}
</style>
import Vue from 'vue'
import { getApplicationConfig } from '@/api/core/system/application'
import { getApplication } from '@/utils/application'

const state = () => ({
  application: {},
})

const getters = {
  application: (state) => state.application,
}

const mutations = {
    setApplication(state, application) {
        state.application = application
    }
}

const actions = {
    async loadConfig({ commit }) {
        const { data } = await getApplicationConfig(getApplication())
        if (data.config && typeof data.config == 'string') {
          data.config = JSON.parse(data.config)
        }
        commit('setApplication', data)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
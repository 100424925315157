/**
 * @description 导出默认通用配置
 */

 const setting = {
    // 生产环境构建文件的目录名
    outputDir: 'dist',
    // 放置生成的静态资源 (js、css、img、fonts) 的 (相对于 outputDir 的) 目录。
    assetsDir: 'static',
    // 开发环境每次保存时是否输出为eslint编译警告
    lintOnSave: true,
    // 进行编译的依赖
    transpileDependencies: [],
    //标题 （包括初次加载雪花屏的标题 页面的标题 浏览器的标题）
    title: process.env.VUE_APP_TITLE,
    //简写
    abbreviation: '',
    //开发环境端口号
    devPort: process.env.VUE_APP_PORT,
    //版本号
    version: process.env.VUE_APP_VERSION,
    copyright: '',
    //是否显示页面底部自定义版权信息
    footerCopyright: false,
    //是否显示顶部进度条
    progressBar: true,
    //缓存路由的最大数量
    keepAliveMaxNum: 99,
    // 路由模式，可选值为 history 或 hash
    routerMode: 'hash',
    //不经过token校验的路由
    routesWhiteList: ['/login', '/logout', '/register', '/oauth/callback', '/oauth/ybcallback', '/cas/callback', '/404', '/401'],
    //加载时显示文字
    loadingText: '正在加载中...',
    //token名称
    // tokenName: 'accessToken',
    tokenName:'user/resetAccessToken',
    //token在localStorage、sessionStorage存储的key的名称
    tokenTableName: 'qz-micr-app-1',
    //token存储位置localStorage sessionStorage
    storage: 'localStorage',
    //token失效回退到登录页时是否记录本次的路由
    recordRoute: true,
    //是否显示logo，不显示时设置false，显示时请填写remixIcon图标名称，暂时只支持设置remixIcon
    logo: 'logo',
    //是否显示在页面高亮错误
    errorLog: ['development', 'production'],
    //是否开启登录拦截
    loginInterception: true,
    //是否开启登录RSA加密
    loginRSA: true,
    //intelligence和all两种方式，前者后端权限只控制permissions不控制view文件的import（前后端配合，减轻后端工作量），all方式完全交给后端前端只负责加载
    authentication: 'intelligence',
    //vertical布局时是否只保持一个子菜单的展开
    uniqueOpened: false,
    //vertical布局时默认展开的菜单path，使用逗号隔开建议只展开一个
    defaultOopeneds: [],
    //需要自动注入并加载的模块
    //providePlugin: { maptalks: 'maptalks', 'window.maptalks': 'maptalks' },
    //npm run build时是否自动生成7z压缩包
    build7z: false,
    //代码生成机生成在view下的文件夹名称
    templateFolder: 'project',
    //是否显示终端donation打印
    donation: true,
    debounce: [],
    staticUrl:process.env.NODE_ENV==='development'?'http://192.168.1.199:9000':'http://eyehealth.inpsmart.com/fastdfs',
  }
  module.exports = setting
  
import Pagination from "./Pagination";
import DictRadio from "./dict/radio";
import DictCheckbox from "./dict/checkbox";
import DictSelect from "./dict/select";
import DateTimeSelect from "./datetime";
import InputSelectButton from '@/components/InputSelectButton'
import GridSelect from "./grid";
import TreeSelect from "./tree";
import AdminSelect from './AdminSelect'
import FileUpload from "./FileUpload";
import FileUploadEx from "./FileUploadEx";
import ImageUpload from "./ImageUpload";
import Dropdown from "./dropdown";
import DetailForm from './DetailForm/DetailForm'
import DetailFormDialog from './DetailForm'
import JFForm from './jfform'
import JFFormItem from './jfform/Item'
import JfTable from './jftable/jftable'

export default {
  install: (Vue) => {
    Vue.component('Pagination', Pagination)
    Vue.component('dict-radio', DictRadio)
    Vue.component('dict-checkbox', DictCheckbox)
    Vue.component('dict-select', DictSelect)
    Vue.component('datetime-select', DateTimeSelect)
    Vue.component('input-select-button', InputSelectButton)
    Vue.component('grid-select', GridSelect)
    Vue.component('tree-select', TreeSelect)
    Vue.component('admin-selectex', AdminSelect)
    Vue.component('file-upload', FileUpload)
    Vue.component('file-upload-ex', FileUploadEx)
    Vue.component('image-upload', ImageUpload)
    Vue.component('el-dropdown-ex', Dropdown)
    Vue.component('detail-form', DetailForm)
    Vue.component('detail-form-dialog', DetailFormDialog)
    Vue.component('jf-form', JFForm)
    Vue.component('jf-form-item', JFFormItem)
	Vue.component('jf-table', JfTable)
  }
} 
/**
* 操作权限处理
*/

import store from '@/store'

export default {
  update(el, binding, vnode, oldVnode) {
    const { value, modifiers } = binding
    const all_permission = "*/*/*";
    const permissions = store.getters && store.getters['user/permissions']

    if (value && value instanceof Array && value.length > 0) {
      const permissionFlag = value
      if (value === oldVnode) {
        return true;
      }

      var hasPermissions = true;


      //是否要全部满足所有权限
      if (modifiers.all != null && modifiers.all) {
        // console.log("all",modifiers.all)
        permissionFlag.forEach(onep => {
          // console.log("onep",onep)
          if (hasPermissions) {
            
            hasPermissions =permissions.includes(all_permission  ) || permissions.includes(onep)
          }

        })

      } else {
        hasPermissions = permissions.some(permission => {
          if (all_permission === permission) {
            return true;
          }
          return permissionFlag.includes(permission)
        })
      }



      if (!hasPermissions) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      console.error("有v-hasPermiZc属性时其值不能为空!")
    }
  },
}

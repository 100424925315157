import request from '@/utils/request'

// 查询字典数据列表
export function listData(query) {
  return request({
    url: '/dict/data/list',
    method: 'get',
    params: query
  })
}

// 查询字典数据详细
export function getData(dictId) {
  return request({
    url: '/dict/data/get?id=' + dictId,
    method: 'get'
  })
}

// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
  return request({
    url: '/dict/get?type=' + dictType,
    method: 'get'
  })
}

// 新增字典数据
export function addData(data) {
  return request({
    url: '/dict/data/add',
    method: 'post',
    data: data
  })
}

// 修改字典数据
export function updateData(data) {
  return request({
    url: '/dict/data/edit',
    method: 'post',
    data: data
  })
}

// 删除字典数据
export function delData(dictId) {
  return request({
    url: '/dict/data/del',
    method: 'post',
    data:{ids:dictId}
  })
}

// 导出字典数据
export function exportData(query) {
  return request({
    url: '/system/dict/data/export',
    method: 'get',
    params: query
  })
}

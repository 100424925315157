<template>
    <el-form-item :label="label" :prop="prop">
        <slot></slot>
    </el-form-item>
</template>
<script>
export default {
    name: 'jf-form-item',
    props: {
        label: String,
        value: [Array, String, Number],
        color: String,
        icon: String,
        slots: String,
        prop: String,
        span: Number
    }
}
</script>
<template>
  <el-date-picker
    v-model="selectedValue"
    :type="type"
    value-format="timestamp"
    :placeholder="placeholder"
    :disabled="disabled"
    :readonly="readonly"
    @change="handleChange"
  />
</template>

<script>
export default {
  name: 'datetime-select',
  props: {
    type: {
      type: String,
      default: () => { return "datetime" }
    },
    value: {
      type: [String, Number],
      default: () => { return undefined }
    },
    placeholder: {
      type: String,
      default: () => { return '请选择' }
    },
    disabled: {
      type: Boolean,
      default: () => { return false }
    },
    readonly: {
      type: Boolean,
      default: () => { return false }
    }
  },
  data() {
    if (!this.value) {
      this.$emit('input', undefined)
      this.$emit('change', undefined)
    }
    return {
      selectedValue: this.value ? (this.value * 1000) : undefined
    }
  },
  created() {
  },
  methods: {
    handleChange(e) {
      const value = this.selectedValue ? (this.selectedValue / 1000) : undefined
      this.$emit('input', value)
      this.$emit('change', value)
    }
  },
  watch: {
    value(val) {
      this.selectedValue = val ? (val * 1000) : undefined
    }
  }
}
</script>
<style scoped>
.dict-select {
  width: 100%;
}
</style>
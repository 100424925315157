<template>
    <div class="filter-btn">
        <div class="mask-popover" @click="visible = false" v-if="visible"></div>
        <el-popover
            placement="bottom-end"
            trigger="manual"
            width="675"
            v-model="visible"
            append-to-body
        >
            <div>
                <div
                    class="font-size-20 mb-10 d-flex align-items-center w-100 justify-content-between"
                >
                    <div class="flex-1">筛选</div>
                    <div class="font-size-14 mr-15">模板</div>
                    <el-select
                        v-model="template_id"
                        placeholder="请选择"
                        style="width: 150px !important"
                        @change="filterTemplateChange"
                        @clear="filterTemplateClear"
                        clearable 
                    >
                        <el-option
                            v-for="item in filterTemplateList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div
                    class="d-flex align-items-center mb-10"
                    v-for="(item, index) in filterActive"
                    :key="index"
                >
                    <div class="flex-1 text-center">
                        {{ index === 0 ? "当" : "且" }}
                    </div>
                    <div class="flex-2 ml-10 z-index-3002">
                        <el-select
                            v-model="item.field"
                            style="width: 100%"
                            @focus="typeVisible = false"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="(title, i) in filterList"
                                :key="i"
                                :label="title.title"
                                :value="title.field"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div class="flex-2 ml-10">
                        <el-select
                            v-model="item.querytype"
                            placeholder="请选择"
                            class="z-index-3002"
                            @focus="typeVisible = false"
                            :disabled="querytypeDisabled(item, index)"
                        >
                            <el-option
                                v-for="(type, i) in calculationTypes"
                                :key="i"
                                :label="type.lable"
                                :value="type.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div class="flex-3 ml-10">
                        <el-select
                            v-model="item.value"
                            placeholder="请选择"
                            style="width: 100%"
                            class="z-index-3002"
                            @focus="typeVisible = false"
                            v-if="
                                valueChange(item.field).form_type * 1 === 2 &&
                                item.querytype !== 'between'
                            "
                        >
                            <el-option
                                v-for="(title, i) in valueChange(item.field)
                                    .options"
                                :key="i"
                                :label="
                                    title[valueChange(item.field).options_label]
                                "
                                :value="
                                    title[valueChange(item.field).options_value]
                                "
                            >
                            </el-option>
                        </el-select>
                        <el-date-picker
                            style="width: 200px !important"
                            v-model="item.value"
                            prefix-icon="el-icon-date"
                            class="z-index-3002"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            format="yyyy-MM-dd HH:mm:ss"
                            @focus="typeVisible = false"
                            v-if="
                                valueChange(item.field).form_type * 1 === 3 &&
                                item.querytype !== 'between'
                            "
                            type="datetime"
                            placeholder="选择日期时间"
                        >
                        </el-date-picker>
                        <el-input
                            placeholder="请输入"
                            v-model="item.value"
                            class="z-index-3002"
                            @focus="typeVisible = false"
                            v-if="
                                (valueChange(item.field).form_type * 1 === 1 ||
                                    !valueChange(item.field).form_type) &&
                                item.querytype !== 'between'
                            "
                        ></el-input>
                        <treeselect
                            v-model="item.value"
                            :options="deptOptions"
                            placeholder="请选择"
                            :normalizer="normalizerDeptTreeNode"
                            v-if="
                                valueChange(item.field).form_type * 1 === 4 &&
                                item.querytype !== 'between'
                            "
                        />
                        <div
                            v-if="
                                valueChange(item.field).form_type * 1 === 5 &&
                                item.querytype !== 'between'
                            "
                            style="position: relative"
                        >
                            <el-popover
                                placement="bottom"
                                width="400"
                                v-model="selectOpen"
                            >
                                <div class="select-box">
                                    <treeselect
                                        v-model="deptId"
                                        :options="deptOptions"
                                        placeholder="请选择部门"
                                        :normalizer="normalizerDeptTreeNode"
                                        style="width: 200px"
                                    />
                                    <el-select
                                        v-model="item.value"
                                        placeholder="请选择人员"
                                        style="width: 200px !important"
                                        @focus="typeVisible = false"
                                        @change="userChange"
                                    >
                                        <el-option
                                            v-for="(
                                                user_item, i
                                            ) in userOptions"
                                            :key="i"
                                            :label="user_item.nickname"
                                            :value="user_item.id"
                                        >
                                        </el-option>
                                    </el-select>
                                </div>

                                <el-input
                                    placeholder="请选择"
                                    slot="reference"
                                    v-model="userName"
                                ></el-input>
                            </el-popover>
                            <!-- <div class="select-box" v-if="selectOpen">
                              <treeselect
                                  v-model="deptId"
                                  :options="deptOptions"
                                  placeholder="请选择部门"
                                  :normalizer="normalizerDeptTreeNode"
                                 style="width:200px"
                               
                              />
                              <el-select
                                  v-model="item.value"
                                  placeholder="请选择人员"
                                  style="width: 200px !important;height: 36px !important;"
                                  class="z-index-3002"
                                  @focus="typeVisible = false"
                                  @change="userChange"
                              >
                                  <el-option
                                      v-for="(user_item, i) in userOptions"
                                      :key="i"
                                      :label="user_item.nickname"
                                      :value="user_item.id"
                                  >
                                  </el-option>
                              </el-select>
                          </div> -->
                        </div>

                        <div
                            class="d-flex align-items-center"
                            v-if="item.querytype === 'between'"
                        >
                            <el-input
                                placeholder="请输入"
                                v-model="item.value1"
                                class="z-index-3002"
                                @focus="typeVisible = false"
                                v-if="
                                    valueChange(item.field).form_type * 1 ===
                                        1 || !valueChange(item.field).form_type
                                "
                            ></el-input>
                            <el-date-picker
                                v-model="item.value1"
                                prefix-icon="el-icon-date"
                                class="z-index-3002"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                @focus="typeVisible = false"
                                v-if="
                                    valueChange(item.field).form_type * 1 === 3
                                "
                                type="datetime"
                                placeholder="选择日期时间"
                            >
                            </el-date-picker>
                            <el-select
                                v-model="item.value1"
                                placeholder="请选择"
                                class="z-index-3002"
                                @focus="typeVisible = false"
                                v-if="
                                    valueChange(item.field).form_type * 1 === 2
                                "
                            >
                                <el-option
                                    v-for="(title, i) in valueChange(item.field)
                                        .options"
                                    :key="i"
                                    :label="
                                        title[
                                            valueChange(item.field)
                                                .options_label
                                        ]
                                    "
                                    :value="
                                        title[
                                            valueChange(item.field)
                                                .options_value
                                        ]
                                    "
                                >
                                </el-option>
                            </el-select>
                            ~
                            <el-input
                                placeholder="请输入"
                                v-model="item.value2"
                                class="z-index-3002"
                                @focus="typeVisible = false"
                                v-if="
                                    valueChange(item.field).form_type * 1 ===
                                        1 || !valueChange(item.field).form_type
                                "
                            ></el-input>
                            <el-date-picker
                                v-model="item.value2"
                                prefix-icon="el-icon-date"
                                class="z-index-3002"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                @focus="typeVisible = false"
                                v-if="
                                    valueChange(item.field).form_type * 1 === 3
                                "
                                type="datetime"
                                placeholder="选择日期时间"
                            >
                            </el-date-picker>
                            <el-select
                                v-model="item.value2"
                                placeholder="请选择"
                                class="z-index-3002"
                                @focus="typeVisible = false"
                                v-if="
                                    valueChange(item.field).form_type * 1 === 2
                                "
                            >
                                <el-option
                                    v-for="(title, i) in valueChange(item.field)
                                        .options"
                                    :key="i"
                                    :label="
                                        title[
                                            valueChange(item.field)
                                                .options_label
                                        ]
                                    "
                                    :value="
                                        title[
                                            valueChange(item.field)
                                                .options_value
                                        ]
                                    "
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="flex-1 text-center ml-10">
                        <i
                            class="el-icon-remove-outline"
                            @click="filterTypeChange(item, index, 2)"
                            style="font-size: 25px; z-index: 3002"
                        ></i>
                    </div>
                </div>
                <div class="filter-bottom" slot="popover">
                    <!-- <el-popover placement="bottom" width="260" v-model="typeVisible"> -->
                    <!-- </el-popover> -->
                    <!-- <div filter-type></div> -->
                    <div
                        class="filter-mask"
                        v-if="typeVisible"
                        @click="typeVisible = false"
                    ></div>
                    <div class="filter-type" v-if="typeVisible">
                        <el-input
                            placeholder="输入筛选条件"
                            v-model="filterKeywords"
                            suffix-icon="el-icon-search"
                        ></el-input>
                        <div class="filter-list">
                            <div
                                class="filter-list-item"
                                v-for="(item, index) in filterTypes"
                                :key="index"
                                :class="{
                                    active:
                                        isExist(filterActive, item.field) > -1,
                                }"
                                @click="filterTypeChange(item, index, 1)"
                            >
                                <div v-text="item.title"></div>
                                <i
                                    class="el-icon-success"
                                    v-if="
                                        isExist(filterActive, item.field) > -1
                                    "
                                ></i>
                            </div>
                        </div>
                    </div>
                    <div class="add-filter-type" @click="typeVisible = true">
                        <i
                            class="el-icon-circle-plus-outline add-filter-type-icon"
                        ></i>
                        添加筛选条件
                    </div>

                    <div>
                        <el-button
                            size="mini"
                            type="success"
                            @click="onSubmit('template')"
                            >保存模板
                        </el-button>
                        <el-button size="mini" type="" @click="empty"
                            >清空值
                        </el-button>
                        <el-button
                            size="mini"
                            type="primary"
                            @click="onSubmit('filter')"
                            >筛选
                        </el-button>
                    </div>
                </div>
            </div>
            <el-button
                class="btn-toolbar"
                slot="reference"
                @click="visible = true"
            >
                <i
                    class="iconfont icon-shaixuan2 btn-toolbar-icon font-size-14"
                ></i>
                {{ btnText }}
            </el-button>
        </el-popover>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import { deepClone } from "../../../vform/utils/util";
import { treeselect } from "@/api/core/system/dept";
import { listUser } from "@/api/core/system/user";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
    components: {
        draggable,
        Treeselect,
    },
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        filterTemplateList: {
            type: Array,
            default: () => [],
        },
        isFilterVisible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            btnText: "筛选",
            startIndex: null,
            typeVisible: false,
            isAll: false,
            visible: false,
            displayVisible: false,
            filterKeywords: "",
            filterActive: [],
            columnList: [],

            // tableTitleColumn: [],
            calculationTypes: [
                {
                    value: ">",
                    lable: "大于",
                },
                {
                    value: "<",
                    lable: "小于",
                },
                {
                    value: "between",
                    lable: "介于",
                },
                {
                    value: "=",
                    lable: "等于",
                },
                {
                    value: "like",
                    lable: "包含",
                },
                {
                    value: ">=",
                    lable: "大于等于",
                },
                {
                    value: "<=",
                    lable: "小于等于",
                },
            ],
            filterList: [],
            templateOptions: [],
            template_id: "",
            deptOptions: [],
            deptId: null,
            userOptions: "",
            selectOpen: false,
            userName: "",
        };
    },
    computed: {
        // this.$emit("update:changeData",e.target.value)
        filterTypes: function () {
            return this.filterList.filter(
                (o) =>
                    o.title &&
                    o.title !== "操作" &&
                    o.title.indexOf(this.filterKeywords) !== -1
            );
        },
        // btnText:function(){

        // }
    },
    watch: {
        list: {
            handler(val) {
                this.filterList = deepClone(val);

                // console.log(val)
            },
            //此属性是控制是否首次加载就会执行，如果不写immediate，或者写immediate：false，
            //两者相同都是首次加载不会执行
            immediate: true,
            //deep:true,深度监听，可以监听对象属性
            deep: true,
        },
        deptId: {
            handler(val) {
                if (val) {
                    this.getUserList();
                }

                // console.log(val)
            },
        },
        isFilterVisible: {
            handler(val) {
                if (val) {
                    this.visible = false;
                }
            },
        },
    },
    created() {},
    methods: {
        filterTemplateClear(){
            
        },
        //模板
        filterTemplateChange(e) {
            this.filterActive = [];
            let index = this.filterTemplateList.findIndex((o) => o.id == e);
            let templateData =
                index > -1 && this.filterTemplateList[index].fieldinfo
                    ? JSON.parse(this.filterTemplateList[index].fieldinfo)
                    : null;

            if (templateData && templateData.length) {
                templateData.forEach((item) => {
                    let i = this.filterList.findIndex(
                        (o) => item.field == o.field
                    );
                    if (i > -1) {
                        let obj = this.filterList[i];
                        obj.value = item.value;
                        obj.querytype = item.querytype;
                        this.filterActive.push(obj);
                    }
                });
            }
        },
        /** 查询机构下拉树结构 */
        getTreeselect() {
            treeselect().then((response) => {
                this.deptOptions = response.data;
            });
        },
        //用户列表
        getUserList(index) {
            listUser({ deptId: this.deptId, pageSize: 50 }).then((response) => {
                this.userOptions = response.data.list;
                // this.filterList[index].options = this.userOptions;
                // this.filterList[index].options_label = "nickname";
                // this.filterList[index].options_value = "id";
                // console.log(this.filterList[index], "this.filterList[index]");
            });
        },
        userChange(e) {
            this.userName = this.userOptions.filter(
                (o) => o.id == e
            )[0].nickname;
            this.selectOpen = false;
        },
        normalizerDeptTreeNode(e) {
            e.id = e.id;
            e.label = e.name;
            return e;
        },
        querytypeDisabled(item, index) {
            let i = this.filterList.findIndex((o) => o.field === item.field);
            if (this.filterList[i].dictType) {
                item.querytype = "=";
                return true;
            } else {
                // item.querytype = this.calculationTypes[0].value;
                return false;
            }
        },
        // templateSubmit() {
        //     this.$emit("change", {
        //         data: list,
        //         name: "template",
        //     });
        // },
        valueChange(filed) {
            let obj = {};

            for (let i = 0; i < this.filterList.length; i++) {
                if (this.filterList[i].field === filed) {
                    obj = this.filterList[i];
                    return obj;
                }
            }
        },
        empty() {
            this.filterActive = [];
            this.btnText = "筛选";
        },

        onSubmit(type) {
            let list = [];
            this.filterActive.forEach((o) => {
                if (o.value || o.value1 || o.value2) {
                    let obj = {
                        logic: "and",
                        field: o.field,
                        querytype: o.querytype,
                        value: o.value ? o.value : `${o.value1}、${o.value2}`,
                    };
                    list.push(obj);
                }
            });
            this.btnText = list.length > 0 ? list.length + "项" : "筛选";

            if (type === "template") {
                this.$prompt("请输入模版名称", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                })
                    .then(({ value }) => {
                        this.$emit("change", {
                            data: list,
                            name: type,
                            template_name: value,
                        });
                        this.visible = true;
                        this.typeVisible = false;
                    })
                    .catch(() => {});
            } else {
                this.$emit("change", {
                    data: list,
                    name: type,
                });
                this.typeVisible = false;
                this.visible = false;
            }
        },
        isExist(list, field) {
            return list.findIndex((o) => o.field === field);
        },
        filterTypeChange(row, index, type) {
            // if (row.field.indexOf("personnelselect") > -1) {
            //     // row.form_type = 2;
            //     this.getUserList();
            // }
            this.typeVisible = false;
            if (type === 2) {
                this.filterActive.splice(index, 1);
                return false;
            }
            let i = this.isExist(this.filterActive, row.field);
            if (i === -1) {
                let obj = this.deepCopy(row);

                obj.logic = "and";
                obj.field = obj.field;
                obj.querytype = this.calculationTypes[0].value;
                obj.value = null;

                this.filterActive.push(obj);
                if (
                    obj.field.indexOf("depselect") > -1 ||
                    obj.field.indexOf("personnelselect") > -1
                ) {
                    this.getTreeselect();
                }

                return false;
            }
            this.filterActive.splice(i, 1);
        },
    },
};
</script>

<style lang="scss" scoped>
.mask-popover {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
}
.filter-btn {
    display: inline-block;
    // float: right;
}
.z-index {
    &-3002 {
        z-index: 3002;
    }
}

.text-primary {
    color: #1890ff;
}

.d-flex {
    display: flex;
}

.flex {
    &-1 {
        flex: 1;
    }

    &-2 {
        flex: 2;
    }

    &-3 {
        flex: 3;
    }
}

.font-size-20 {
    font-size: 20px;
}

.text-center {
    text-align: center;
}

.align-items-center {
    align-items: center;
}

.ml-10 {
    margin-left: 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.filter-list {
    padding-top: 10px;
    max-height: 200px;
    overflow-y: auto;

    &-item {
        padding: 0 10px;
        height: 35px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 5px 0;
        cursor: pointer;
        border-radius: 5px;
        color: rgba(0, 0, 0, 0.55);

        &:hover {
            background: rgba(24, 144, 255, 0.2);
            color: #1890ff;
        }

        &.active {
            background: rgba(24, 144, 255, 0.2);
            color: #1890ff;
        }
    }
}

.filter-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    position: relative;

    .filter-mask {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 3000;
    }

    .filter-type {
        position: absolute;
        background-color: #fff;
        top: 20px;
        border-radius: 10px;
        padding: 10px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        z-index: 3001;
    }

    .add-filter-type {
        color: #1890ff;
        display: flex;
        cursor: pointer;
        align-items: center;
        line-height: 25px;

        &-icon {
            font-size: 25px;
            margin-right: 5px;
        }
    }
}

.btn-toolbar {
    // float: right;
    border: none !important;
    margin: 0 12px 0 0 !important;
    padding: 0 0.5em !important;
    height: 32px;
    line-height: 32px;

    &-icon {
        margin-right: 5px;
    }
}

.filter-btn .btn-toolbar:hover,
.filter-btn .btn-toolbar:focus {
    background: transparent !important;
}

.filter-btn .vxe-tools--wrapper {
    .vxe-button {
        border: none !important;
        margin: 0 12px 0 0 !important;
        padding: 0 0.5em !important;
    }
}

.column-list {
    padding: 15px;
    max-height: 300px;
    overflow-y: auto;

    &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        line-height: 1;
        padding: 10px;
        margin-top: 10px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        background: #fff;
    }
}

// .custom-dragging {
// 	background-color:#1890ff;
// }

.sortable-ghost {
    background: #fff !important;
    opacity: 0 !important;
}
.select-box {
    display: flex;
    align-items: center;
    // background: #fff;
    // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    // position: absolute;
    // padding: 15px;
    // border-radius: 10px;
    // z-index: 1;
}

// .column-list-item
</style>
<style>
.select-box .vue-treeselect__control {
    height: 32px;
    border-radius: 4px;
}
</style>
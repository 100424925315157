<template>
  <el-radio-group v-model="selectedValue" @change="handleChange" :disabled="disabled">
    <el-radio
      v-for="dict in dictData.values"
      :key="dict.key"
      :label="dict.key"
    >{{dict.value}}</el-radio>
  </el-radio-group>
</template>

<script>
export default {
  name: 'dict-radio',
  props: {
    type: {
      type: String,
      default: () => { return null }
    },
    value: {
      type: String,
      default: () => { return null }
    },
    disabled: {
      type: Boolean,
      default: () => { return false }
    }
  },
  data() {
    return {
      dictData: {},
      selectedValue: this.value
    }
  },
  created() {
    this.getDicts(this.type).then(response => {
      if (response.code == 0) {
        this.dictData = response.data
      }
    })
  },
  methods: {
    handleChange(e) {
      this.$emit('input', this.selectedValue)
      this.$emit('change', this.selectedValue)
    }
  },
  watch: {
    value(val) {
      this.selectedValue = val
    }
  }
}
</script>
